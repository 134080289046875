import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
  ResourceData: any;
};

export type Action = Node & {
  __typename?: 'Action';
  id: Scalars['ID'];
  description: Scalars['String'];
  status: ActionStatus;
  createdBy?: Maybe<User>;
  assignedTo?: Maybe<User>;
  deadlineAt?: Maybe<Scalars['Date']>;
  cost?: Maybe<Scalars['Float']>;
  manDays?: Maybe<Scalars['Float']>;
  isOverdue: Scalars['Boolean'];
  determinationDate?: Maybe<Scalars['Date']>;
  audit?: Maybe<Audit>;
  requirement?: Maybe<Requirement>;
  assets?: Maybe<Array<Maybe<UserAsset>>>;
};

export type ActionAggregate = {
  __typename?: 'ActionAggregate';
  status: ActionStatus;
  count: Scalars['Int'];
};

export type ActionFilter = {
  assignedToId?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  auditId?: Maybe<Scalars['ID']>;
  status?: Maybe<ActionStatus>;
};

export type ActionPaginationResult = PaginatedResult & {
  __typename?: 'ActionPaginationResult';
  items: Array<Action>;
  pageInfo: PaginationInfo;
};

export type ActionSort = {
  value: ActionSortValue;
  direction?: Maybe<SortDirection>;
};

export enum ActionSortValue {
  DeadlineAt = 'DEADLINE_AT',
  Cost = 'COST',
  ManDays = 'MAN_DAYS'
}

export enum ActionStatus {
  Completed = 'COMPLETED',
  Open = 'OPEN',
  Cancelled = 'CANCELLED',
  ToStart = 'TO_START'
}

export type AmendmentSummary = Node & {
  __typename?: 'AmendmentSummary';
  id: Scalars['ID'];
  content: Scalars['String'];
  implementationDate: Scalars['Date'];
};

export type Asset = {
  source: Scalars['String'];
  mimeType: Scalars['String'];
  size: Scalars['String'];
};

export type AssetInput = {
  id: Scalars['ID'];
  fileName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  mimeType?: Maybe<Scalars['String']>;
};

export type Audit = Node & {
  __typename?: 'Audit';
  id: Scalars['ID'];
  name: Scalars['String'];
  year: Scalars['String'];
  quarter: Quarter;
  auditor?: Maybe<User>;
  sites: Array<Site>;
  themes: Array<Theme>;
  rootRequirements: Array<Requirement>;
  addedRootRequirements: Array<Requirement>;
  changedRootRequirements: Array<Requirement>;
  requirementAggregates: Array<RequirementAggregate>;
  actionAggregates: Array<ActionAggregate>;
};

export type AuditPaginatedResult = PaginatedResult & {
  __typename?: 'AuditPaginatedResult';
  items: Array<Audit>;
  pageInfo: PaginationInfo;
};

export type AuditRapport = Node & {
  __typename?: 'AuditRapport';
  id: Scalars['ID'];
  status: AuditRapportStatus;
  source?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export enum AuditRapportStatus {
  Initiated = 'INITIATED',
  AggregatingData = 'AGGREGATING_DATA',
  FinishedAggregatingData = 'FINISHED_AGGREGATING_DATA',
  GeneratingRapport = 'GENERATING_RAPPORT',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

export type AuditsFilter = {
  companyId?: Maybe<Scalars['ID']>;
};

export enum BaseAuditActionPolicy {
  Copy = 'COPY',
  Move = 'MOVE',
  None = 'NONE'
}

export type BaseAuditInput = {
  baseAuditId: Scalars['ID'];
  actionPolicy: BaseAuditActionPolicy;
  remarkPolicy: BaseAuditRemarkPolicy;
  statusPolicy: BaseAuditStatusPolicy;
};

export enum BaseAuditRemarkPolicy {
  Copy = 'COPY',
  Move = 'MOVE',
  None = 'NONE'
}

export enum BaseAuditStatusPolicy {
  Copy = 'COPY',
  None = 'NONE'
}

export type Company = Node & {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  subscription: CompanySubscription;
  themes: Array<Theme>;
  siteCount: Scalars['Int'];
  userCount: Scalars['Int'];
};

export type CompanyPaginatedResult = PaginatedResult & {
  __typename?: 'CompanyPaginatedResult';
  items: Array<Company>;
  pageInfo: PaginationInfo;
};

export type CompanySubscription = Node & {
  __typename?: 'CompanySubscription';
  id: Scalars['ID'];
  slug: Scalars['String'];
  name: Scalars['String'];
  type: SubscriptionType;
};

export type CreateActionInput = {
  description: Scalars['String'];
  assignedToId?: Maybe<Scalars['ID']>;
  deadlineAt?: Maybe<Scalars['Date']>;
  cost?: Maybe<Scalars['Float']>;
  manDays?: Maybe<Scalars['Float']>;
  determinationDate: Scalars['Date'];
  companyId: Scalars['ID'];
  auditId?: Maybe<Scalars['ID']>;
  requirementId?: Maybe<Scalars['ID']>;
  assets?: Maybe<Array<Maybe<AssetInput>>>;
  shouldNotifyAssignee?: Maybe<Scalars['Boolean']>;
};

export type CreateActionResult = {
  __typename?: 'CreateActionResult';
  success: Scalars['Boolean'];
  action?: Maybe<Action>;
};

export type CreateAmendmentSummaryInput = {
  resourceId: Scalars['ID'];
  content: Scalars['String'];
  implementationDate: Scalars['Date'];
};

export type CreateAmendmentSummaryResult = {
  __typename?: 'CreateAmendmentSummaryResult';
  success: Scalars['Boolean'];
  amendmentSummary?: Maybe<AmendmentSummary>;
};

export type CreateAuditInput = {
  name: Scalars['String'];
  year: Scalars['String'];
  quarter: Quarter;
  companyId: Scalars['ID'];
  siteIds: Array<Scalars['ID']>;
  themeIds: Array<Scalars['ID']>;
  /** Only ID's of root resources should be passed. */
  resourceIds: Array<Scalars['ID']>;
  auditedById: Scalars['ID'];
  baseAudit?: Maybe<BaseAuditInput>;
};

export type CreateAuditRapportInput = {
  auditId: Scalars['ID'];
  themeIds: Array<Scalars['ID']>;
  requirementOverviewStatuses: Array<RequirementStatus>;
  requirementDetailStatuses: Array<RequirementStatus>;
  requirementActionStatuses: Array<ActionStatus>;
  shouldIncludeRemarks: Scalars['Boolean'];
  independentActionStatuses: Array<ActionStatus>;
};

export type CreateAuditRapportResult = {
  __typename?: 'CreateAuditRapportResult';
  success: Scalars['Boolean'];
  rapport: AuditRapport;
};

export type CreateAuditResult = JobResult & {
  __typename?: 'CreateAuditResult';
  success: Scalars['Boolean'];
  jobId: Scalars['ID'];
  audit?: Maybe<Audit>;
};

export type CreateCompanyInput = {
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
  subscriptionId: Scalars['ID'];
  themeIds: Array<Scalars['ID']>;
};

export type CreateCompanyResult = {
  __typename?: 'CreateCompanyResult';
  success: Scalars['Boolean'];
  company?: Maybe<Company>;
};

export type CreateRemarkInput = {
  content: Scalars['String'];
  auditId?: Maybe<Scalars['ID']>;
  requirementId?: Maybe<Scalars['ID']>;
  determinationDate: Scalars['Date'];
  identifiedBy?: Maybe<Scalars['String']>;
  assets?: Maybe<Array<Maybe<AssetInput>>>;
};

export type CreateRemarkResult = {
  __typename?: 'CreateRemarkResult';
  success: Scalars['Boolean'];
  remark?: Maybe<Remark>;
};

export type CreateSiteInput = {
  name: Scalars['String'];
  address: Scalars['String'];
  postcode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  isHeadquarters: Scalars['Boolean'];
  companyId: Scalars['ID'];
};

export type CreateSiteResult = {
  __typename?: 'CreateSiteResult';
  success: Scalars['Boolean'];
  site?: Maybe<Site>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
  roleIds: Array<Scalars['ID']>;
  shouldSendActivationMail?: Maybe<Scalars['Boolean']>;
};

export type CreateUserResult = {
  __typename?: 'CreateUserResult';
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};



export type DeleteActionInput = {
  id: Scalars['ID'];
};

export type DeleteActionResult = {
  __typename?: 'DeleteActionResult';
  success: Scalars['Boolean'];
};

export type DeleteAmendmentSummaryInput = {
  id: Scalars['ID'];
};

export type DeleteAmendmentSummaryResult = {
  __typename?: 'DeleteAmendmentSummaryResult';
  success: Scalars['Boolean'];
};

export type DeleteAuditInput = {
  id: Scalars['ID'];
};

export type DeleteAuditRapportInput = {
  id: Scalars['ID'];
};

export type DeleteAuditRapportResult = {
  __typename?: 'DeleteAuditRapportResult';
  success: Scalars['Boolean'];
};

export type DeleteAuditResult = {
  __typename?: 'DeleteAuditResult';
  success: Scalars['Boolean'];
};

export type DeleteCompanyInput = {
  id: Scalars['ID'];
};

export type DeleteCompanyResult = {
  __typename?: 'DeleteCompanyResult';
  success: Scalars['Boolean'];
};

export type DeleteRemarkInput = {
  id: Scalars['ID'];
};

export type DeleteRemarkResult = {
  __typename?: 'DeleteRemarkResult';
  success: Scalars['Boolean'];
};

export type DeleteRequirementInput = {
  id: Scalars['ID'];
};

export type DeleteRequirementResult = {
  __typename?: 'DeleteRequirementResult';
  success: Scalars['Boolean'];
};

export type DeleteSiteInput = {
  id: Scalars['ID'];
};

export type DeleteSiteResult = {
  __typename?: 'DeleteSiteResult';
  success: Scalars['Boolean'];
};

export type DeleteUserInput = {
  id: Scalars['ID'];
};

export type DeleteUserResult = {
  __typename?: 'DeleteUserResult';
  success: Scalars['Boolean'];
};

export type ExportActionsResult = {
  __typename?: 'ExportActionsResult';
  url: Scalars['String'];
};

export type InitiatePasswordResetInput = {
  id: Scalars['ID'];
};

export type InitiatePasswordResetResult = {
  __typename?: 'InitiatePasswordResetResult';
  success: Scalars['Boolean'];
};


export type JobResult = {
  jobId?: Maybe<Scalars['ID']>;
};

export enum JobStatus {
  Queued = 'QUEUED',
  Running = 'RUNNING',
  Finished = 'FINISHED',
  Failed = 'FAILED'
}

export type JobStatusResult = {
  __typename?: 'JobStatusResult';
  status: JobStatus;
};

export type LegalAsset = Asset & Node & {
  __typename?: 'LegalAsset';
  id: Scalars['ID'];
  title: Scalars['String'];
  source: Scalars['String'];
  mimeType: Scalars['String'];
  size: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: CreateUserResult;
  updateUser: UpdateUserResult;
  deleteUser: DeleteUserResult;
  initiatePasswordReset: InitiatePasswordResetResult;
  createCompany: CreateCompanyResult;
  updateCompany: UpdateCompanyResult;
  deleteCompany: DeleteCompanyResult;
  createSite: CreateSiteResult;
  updateSite: UpdateSiteResult;
  deleteSite: DeleteSiteResult;
  updateUserAccessForCompany: UpdateUserAccessForCompanyResult;
  updateUserTenantAccess: UpdateUserTenantAccessResult;
  updateSiteAccess: UpdateSiteAccessResult;
  updateResourceSummary: UpdateResourceSummaryResult;
  createAmendmentSummary: CreateAmendmentSummaryResult;
  updateAmendmentSummary: UpdateAmendmentSummaryResult;
  deleteAmendmentSummary: DeleteAmendmentSummaryResult;
  createAudit: CreateAuditResult;
  updateAudit: UpdateAuditResult;
  deleteAudit: DeleteAuditResult;
  deleteRequirement: DeleteRequirementResult;
  updateRequirementStatus: UpdateRequirementStatusResult;
  createAuditRapport: CreateAuditRapportResult;
  deleteAuditRapport: DeleteAuditRapportResult;
  createAction: CreateActionResult;
  updateAction: UpdateActionResult;
  deleteAction: DeleteActionResult;
  createRemark: CreateRemarkResult;
  updateRemark: UpdateRemarkResult;
  deleteRemark: DeleteRemarkResult;
};


export type MutationCreateUserArgs = {
  user: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  user: UpdateUserInput;
};


export type MutationDeleteUserArgs = {
  user: DeleteUserInput;
};


export type MutationInitiatePasswordResetArgs = {
  input: InitiatePasswordResetInput;
};


export type MutationCreateCompanyArgs = {
  company: CreateCompanyInput;
};


export type MutationUpdateCompanyArgs = {
  company: UpdateCompanyInput;
};


export type MutationDeleteCompanyArgs = {
  company: DeleteCompanyInput;
};


export type MutationCreateSiteArgs = {
  site: CreateSiteInput;
};


export type MutationUpdateSiteArgs = {
  site: UpdateSiteInput;
};


export type MutationDeleteSiteArgs = {
  site: DeleteSiteInput;
};


export type MutationUpdateUserAccessForCompanyArgs = {
  input: UpdateUserAccessForCompanyInput;
};


export type MutationUpdateUserTenantAccessArgs = {
  input: UpdateUserTenantAccessInput;
};


export type MutationUpdateSiteAccessArgs = {
  input: UpdateSiteAccessInput;
};


export type MutationUpdateResourceSummaryArgs = {
  resourceSummary: UpdateResourceSummaryInput;
};


export type MutationCreateAmendmentSummaryArgs = {
  amendmentSummary: CreateAmendmentSummaryInput;
};


export type MutationUpdateAmendmentSummaryArgs = {
  amendmentSummary: UpdateAmendmentSummaryInput;
};


export type MutationDeleteAmendmentSummaryArgs = {
  amendmentSummary: DeleteAmendmentSummaryInput;
};


export type MutationCreateAuditArgs = {
  audit: CreateAuditInput;
};


export type MutationUpdateAuditArgs = {
  audit: UpdateAuditInput;
};


export type MutationDeleteAuditArgs = {
  audit: DeleteAuditInput;
};


export type MutationDeleteRequirementArgs = {
  requirement: DeleteRequirementInput;
};


export type MutationUpdateRequirementStatusArgs = {
  requirement: UpdateRequirementStatusInput;
};


export type MutationCreateAuditRapportArgs = {
  rapport: CreateAuditRapportInput;
};


export type MutationDeleteAuditRapportArgs = {
  rapport: DeleteAuditRapportInput;
};


export type MutationCreateActionArgs = {
  action: CreateActionInput;
};


export type MutationUpdateActionArgs = {
  action: UpdateActionInput;
};


export type MutationDeleteActionArgs = {
  action: DeleteActionInput;
};


export type MutationCreateRemarkArgs = {
  remark: CreateRemarkInput;
};


export type MutationUpdateRemarkArgs = {
  remark: UpdateRemarkInput;
};


export type MutationDeleteRemarkArgs = {
  remark: DeleteRemarkInput;
};

/** Any object implementing this type can be found by ID through `Query.node`. */
export type Node = {
  /** Global identifier that can be used to resolve any Node implementation. */
  id: Scalars['ID'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Minimum. */
  Min = 'MIN',
  /** Maximum. */
  Max = 'MAX',
  /** Sum. */
  Sum = 'SUM',
  /** Amount of items. */
  Count = 'COUNT'
}

export type PageFilter = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
};

export type PaginatedResult = {
  items: Array<Node>;
  pageInfo: PaginationInfo;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  count: Scalars['Int'];
  total: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  lastPage?: Maybe<Scalars['Int']>;
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Permission = Node & {
  __typename?: 'Permission';
  id: Scalars['ID'];
  slug: Scalars['String'];
  name: Scalars['String'];
  group: Scalars['String'];
};

export enum Quarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export type Query = {
  __typename?: 'Query';
  jobStatus: JobStatusResult;
  users: UserPaginationResult;
  user?: Maybe<User>;
  roles: Array<Role>;
  viewer: User;
  viewerCompanies: Array<Company>;
  companies: CompanyPaginatedResult;
  company?: Maybe<Company>;
  companyUsers: Array<User>;
  tenantUsers: Array<User>;
  companySites: Array<Site>;
  site?: Maybe<Site>;
  siteUsers: Array<User>;
  userCompanySites: Array<Site>;
  subscriptions: Array<CompanySubscription>;
  laws: Array<Resource>;
  themes: Array<Theme>;
  resource: Resource;
  resources: ResourcePaginatedResult;
  audits: AuditPaginatedResult;
  audit?: Maybe<Audit>;
  requirement?: Maybe<Requirement>;
  childRequirements: Array<Requirement>;
  requirementStatuses: Array<RequirementStatus>;
  getAuditRapports: Array<AuditRapport>;
  actions: ActionPaginationResult;
  requirementActions: ActionPaginationResult;
  exportActions: ExportActionsResult;
  remarks: RemarkPaginationResult;
  requirementRemarks: RemarkPaginationResult;
  node?: Maybe<Node>;
};


export type QueryJobStatusArgs = {
  jobId: Scalars['ID'];
};


export type QueryUsersArgs = {
  search?: Maybe<Scalars['String']>;
  page?: Maybe<PageFilter>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryCompaniesArgs = {
  search?: Maybe<Scalars['String']>;
  page?: Maybe<PageFilter>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyUsersArgs = {
  companyId: Scalars['ID'];
};


export type QueryCompanySitesArgs = {
  companyId: Scalars['ID'];
};


export type QuerySiteArgs = {
  id: Scalars['ID'];
};


export type QuerySiteUsersArgs = {
  siteId: Scalars['ID'];
};


export type QueryUserCompanySitesArgs = {
  userId: Scalars['ID'];
  companyId: Scalars['ID'];
};


export type QueryThemesArgs = {
  companyId?: Maybe<Scalars['ID']>;
};


export type QueryResourceArgs = {
  id: Scalars['ID'];
};


export type QueryResourcesArgs = {
  parentId: Scalars['ID'];
  page?: Maybe<PageFilter>;
};


export type QueryAuditsArgs = {
  search?: Maybe<Scalars['String']>;
  filter?: Maybe<AuditsFilter>;
  page?: Maybe<PageFilter>;
};


export type QueryAuditArgs = {
  id: Scalars['ID'];
};


export type QueryRequirementArgs = {
  id: Scalars['ID'];
};


export type QueryChildRequirementsArgs = {
  id: Scalars['ID'];
};


export type QueryRequirementStatusesArgs = {
  companyId: Scalars['ID'];
};


export type QueryGetAuditRapportsArgs = {
  auditId: Scalars['ID'];
};


export type QueryActionsArgs = {
  page?: Maybe<PageFilter>;
  filter?: Maybe<ActionFilter>;
  sort?: Maybe<ActionSort>;
};


export type QueryRequirementActionsArgs = {
  requirementId: Scalars['ID'];
  page?: Maybe<PageFilter>;
};


export type QueryExportActionsArgs = {
  filter?: Maybe<ActionFilter>;
  sort?: Maybe<ActionSort>;
};


export type QueryRemarksArgs = {
  page?: Maybe<PageFilter>;
  filter?: Maybe<RemarkFilter>;
};


export type QueryRequirementRemarksArgs = {
  requirementId: Scalars['ID'];
  page?: Maybe<PageFilter>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type Remark = Node & {
  __typename?: 'Remark';
  id: Scalars['ID'];
  content: Scalars['String'];
  determinationDate?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  identifiedBy?: Maybe<Scalars['String']>;
  audit?: Maybe<Audit>;
  requirement?: Maybe<Requirement>;
  assets?: Maybe<Array<Maybe<UserAsset>>>;
};

export type RemarkFilter = {
  siteId?: Maybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  auditId?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
};

export type RemarkPaginationResult = PaginatedResult & {
  __typename?: 'RemarkPaginationResult';
  items: Array<Remark>;
  pageInfo: PaginationInfo;
};

export type Requirement = Node & {
  __typename?: 'Requirement';
  id: Scalars['ID'];
  siteStatuses: Array<RequirementSiteStatus>;
  resource: Resource;
  resourceStatus?: Maybe<RequirementResourceStatus>;
  actionCount: Scalars['Int'];
  remarkCount: Scalars['Int'];
  requirementAggregates: Array<RequirementAggregate>;
  actionAggregates: Array<ActionAggregate>;
};

export type RequirementAggregate = {
  __typename?: 'RequirementAggregate';
  status?: Maybe<RequirementStatus>;
  count: Scalars['Int'];
};

export enum RequirementResourceStatus {
  Unchanged = 'UNCHANGED',
  Changed = 'CHANGED',
  ChangedChild = 'CHANGED_CHILD',
  Added = 'ADDED'
}

export type RequirementSiteStatus = Node & {
  __typename?: 'RequirementSiteStatus';
  id: Scalars['ID'];
  site: Site;
  status?: Maybe<RequirementStatus>;
};

export enum RequirementStatus {
  Yes = 'YES',
  No = 'NO',
  Conform = 'CONFORM',
  Improvement = 'IMPROVEMENT',
  LargeNonConformity = 'LARGE_NON_CONFORMITY',
  SmallNonConformity = 'SMALL_NON_CONFORMITY',
  DoesNotApply = 'DOES_NOT_APPLY',
  ToBeDecided = 'TO_BE_DECIDED',
  Informative = 'INFORMATIVE'
}

export type Resource = Node & {
  __typename?: 'Resource';
  id: Scalars['ID'];
  externalResourceReference: Scalars['String'];
  type: ResourceType;
  theme?: Maybe<Theme>;
  implementationDate: Scalars['Date'];
  data: Scalars['ResourceData'];
  summary?: Maybe<ResourceSummary>;
  amendmentSummaries: Array<AmendmentSummary>;
  hasChildren: Scalars['Boolean'];
  downloadableDocuments?: Maybe<Array<LegalAsset>>;
  root?: Maybe<Resource>;
};


export type ResourcePaginatedResult = PaginatedResult & {
  __typename?: 'ResourcePaginatedResult';
  items: Array<Resource>;
  pageInfo: PaginationInfo;
};

export type ResourceSummary = Node & {
  __typename?: 'ResourceSummary';
  id: Scalars['ID'];
  content: Scalars['String'];
};

export enum ResourceType {
  Law = 'LAW',
  Annotation = 'ANNOTATION',
  AnnotationGroup = 'ANNOTATION_GROUP',
  Article = 'ARTICLE',
  Attachment = 'ATTACHMENT',
  Book = 'BOOK',
  Chapter = 'CHAPTER',
  Department = 'DEPARTMENT',
  SubDepartment = 'SUB_DEPARTMENT',
  Inscription = 'INSCRIPTION',
  PseudoArticle = 'PSEUDO_ARTICLE',
  Title = 'TITLE',
  Note = 'NOTE'
}

export type Role = Node & {
  __typename?: 'Role';
  id: Scalars['ID'];
  slug: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Permission>;
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
};

export type Site = Node & {
  __typename?: 'Site';
  id: Scalars['ID'];
  name: Scalars['String'];
  fullAddress: Scalars['String'];
  address: Scalars['String'];
  postcode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  isHeadquarters: Scalars['Boolean'];
  userCount: Scalars['Int'];
  company: Company;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export enum SubscriptionType {
  Basic = 'BASIC',
  Premium = 'PREMIUM'
}

export type Tenant = Node & {
  __typename?: 'Tenant';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Theme = Node & {
  __typename?: 'Theme';
  id: Scalars['ID'];
  slug: Scalars['String'];
  name: Scalars['String'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UpdateActionInput = {
  id: Scalars['ID'];
  status: ActionStatus;
  description: Scalars['String'];
  assignedToId?: Maybe<Scalars['ID']>;
  deadlineAt?: Maybe<Scalars['Date']>;
  cost?: Maybe<Scalars['Float']>;
  manDays?: Maybe<Scalars['Float']>;
  determinationDate?: Maybe<Scalars['Date']>;
  assets?: Maybe<Array<Maybe<AssetInput>>>;
  shouldNotifyAssignee?: Maybe<Scalars['Boolean']>;
};

export type UpdateActionResult = {
  __typename?: 'UpdateActionResult';
  success: Scalars['Boolean'];
  action?: Maybe<Action>;
};

export type UpdateAmendmentSummaryInput = {
  id: Scalars['ID'];
  content: Scalars['String'];
  implementationDate: Scalars['Date'];
};

export type UpdateAmendmentSummaryResult = {
  __typename?: 'UpdateAmendmentSummaryResult';
  success: Scalars['Boolean'];
  amendmentSummary?: Maybe<AmendmentSummary>;
};

export type UpdateAuditInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  auditedById?: Maybe<Scalars['ID']>;
};

export type UpdateAuditResult = {
  __typename?: 'UpdateAuditResult';
  success: Scalars['Boolean'];
  audit?: Maybe<Audit>;
};

export type UpdateCompanyInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
  subscriptionId: Scalars['ID'];
  themeIds: Array<Scalars['ID']>;
};

export type UpdateCompanyResult = {
  __typename?: 'UpdateCompanyResult';
  success: Scalars['Boolean'];
  company?: Maybe<Company>;
};

export type UpdateRemarkInput = {
  id: Scalars['ID'];
  content: Scalars['String'];
  determinationDate?: Maybe<Scalars['Date']>;
  identifiedBy?: Maybe<Scalars['String']>;
  assets?: Maybe<Array<Maybe<AssetInput>>>;
};

export type UpdateRemarkResult = {
  __typename?: 'UpdateRemarkResult';
  success: Scalars['Boolean'];
  remark?: Maybe<Remark>;
};

export type UpdateRequirementStatusInput = {
  id: Scalars['ID'];
  status?: Maybe<RequirementStatus>;
  siteIds?: Maybe<Array<Scalars['ID']>>;
  shouldUpdateChildRequirements: Scalars['Boolean'];
};

export type UpdateRequirementStatusResult = {
  __typename?: 'UpdateRequirementStatusResult';
  success: Scalars['Boolean'];
  requirement?: Maybe<Requirement>;
  parentRequirements?: Maybe<Array<Requirement>>;
};

export type UpdateResourceSummaryInput = {
  resourceId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
};

export type UpdateResourceSummaryResult = {
  __typename?: 'UpdateResourceSummaryResult';
  success: Scalars['Boolean'];
  resource: Resource;
};

export type UpdateSiteAccessInput = {
  userIds?: Maybe<Array<Scalars['ID']>>;
  siteId: Scalars['ID'];
};

export type UpdateSiteAccessResult = {
  __typename?: 'UpdateSiteAccessResult';
  success: Scalars['Boolean'];
};

export type UpdateSiteInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  postcode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  isHeadquarters: Scalars['Boolean'];
};

export type UpdateSiteResult = {
  __typename?: 'UpdateSiteResult';
  success: Scalars['Boolean'];
  site?: Maybe<Site>;
};

export type UpdateUserAccessForCompanyInput = {
  userId: Scalars['ID'];
  companyId: Scalars['ID'];
  siteIds: Array<Scalars['ID']>;
};

export type UpdateUserAccessForCompanyResult = {
  __typename?: 'UpdateUserAccessForCompanyResult';
  success: Scalars['Boolean'];
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
  roleIds: Array<Scalars['ID']>;
};

export type UpdateUserResult = {
  __typename?: 'UpdateUserResult';
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type UpdateUserTenantAccessInput = {
  userId: Scalars['ID'];
  tenantId: Scalars['ID'];
};

export type UpdateUserTenantAccessResult = {
  __typename?: 'UpdateUserTenantAccessResult';
  success: Scalars['Boolean'];
};

export type User = Node & {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  tenant?: Maybe<Tenant>;
  inviteCode?: Maybe<Scalars['String']>;
};

export type UserAsset = Node & {
  __typename?: 'UserAsset';
  id: Scalars['ID'];
  externalReference: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
  size: Scalars['Int'];
};

export type UserPaginationResult = {
  __typename?: 'UserPaginationResult';
  items: Array<User>;
  pageInfo: PaginationInfo;
};

export type GetViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetViewerQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'slug'>
    )>, tenant?: Maybe<(
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id' | 'name'>
    )> }
  ) }
);

export type CreateActionMutationVariables = Exact<{
  action: CreateActionInput;
}>;


export type CreateActionMutation = (
  { __typename?: 'Mutation' }
  & { createAction: (
    { __typename?: 'CreateActionResult' }
    & Pick<CreateActionResult, 'success'>
    & { action?: Maybe<(
      { __typename?: 'Action' }
      & Pick<Action, 'id' | 'description' | 'status' | 'cost' | 'manDays' | 'deadlineAt' | 'determinationDate'>
      & { assignedTo?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )>, assets?: Maybe<Array<Maybe<(
        { __typename?: 'UserAsset' }
        & Pick<UserAsset, 'id' | 'fileName' | 'size' | 'mimeType'>
      )>>> }
    )> }
  ) }
);

export type DeleteActionMutationVariables = Exact<{
  action: DeleteActionInput;
}>;


export type DeleteActionMutation = (
  { __typename?: 'Mutation' }
  & { deleteAction: (
    { __typename?: 'DeleteActionResult' }
    & Pick<DeleteActionResult, 'success'>
  ) }
);

export type ExportActionsQueryVariables = Exact<{
  filter?: Maybe<ActionFilter>;
  sort?: Maybe<ActionSort>;
}>;


export type ExportActionsQuery = (
  { __typename?: 'Query' }
  & { exportActions: (
    { __typename?: 'ExportActionsResult' }
    & Pick<ExportActionsResult, 'url'>
  ) }
);

export type GetOverviewActionsQueryVariables = Exact<{
  page: PageFilter;
  filter?: Maybe<ActionFilter>;
  sort?: Maybe<ActionSort>;
}>;


export type GetOverviewActionsQuery = (
  { __typename?: 'Query' }
  & { actions: (
    { __typename?: 'ActionPaginationResult' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'count' | 'perPage' | 'page' | 'total' | 'lastPage'>
    ), items: Array<(
      { __typename?: 'Action' }
      & Pick<Action, 'id' | 'description' | 'cost' | 'manDays' | 'deadlineAt' | 'determinationDate' | 'status' | 'isOverdue'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )>, assignedTo?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )>, requirement?: Maybe<(
        { __typename?: 'Requirement' }
        & Pick<Requirement, 'id'>
        & { resource: (
          { __typename?: 'Resource' }
          & Pick<Resource, 'id' | 'type' | 'data'>
          & { root?: Maybe<(
            { __typename?: 'Resource' }
            & Pick<Resource, 'id' | 'type' | 'data'>
          )> }
        ) }
      )>, assets?: Maybe<Array<Maybe<(
        { __typename?: 'UserAsset' }
        & Pick<UserAsset, 'id' | 'fileName' | 'mimeType' | 'size'>
      )>>>, audit?: Maybe<(
        { __typename?: 'Audit' }
        & Pick<Audit, 'id' | 'name'>
        & { sites: Array<(
          { __typename?: 'Site' }
          & Pick<Site, 'id' | 'name'>
        )> }
      )> }
    )> }
  ) }
);

export type GetRequirementActionsQueryVariables = Exact<{
  requirementId: Scalars['ID'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
}>;


export type GetRequirementActionsQuery = (
  { __typename?: 'Query' }
  & { requirementActions: (
    { __typename?: 'ActionPaginationResult' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'lastPage' | 'page'>
    ), items: Array<(
      { __typename?: 'Action' }
      & Pick<Action, 'id' | 'description' | 'status' | 'isOverdue' | 'cost' | 'manDays' | 'deadlineAt' | 'determinationDate'>
      & { assets?: Maybe<Array<Maybe<(
        { __typename?: 'UserAsset' }
        & Pick<UserAsset, 'id' | 'fileName' | 'mimeType' | 'size'>
      )>>>, assignedTo?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
  ) }
);

export type UpdateActionMutationVariables = Exact<{
  action: UpdateActionInput;
}>;


export type UpdateActionMutation = (
  { __typename?: 'Mutation' }
  & { updateAction: (
    { __typename?: 'UpdateActionResult' }
    & Pick<UpdateActionResult, 'success'>
    & { action?: Maybe<(
      { __typename?: 'Action' }
      & Pick<Action, 'id' | 'description' | 'status' | 'cost' | 'manDays' | 'deadlineAt' | 'determinationDate'>
      & { assignedTo?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )>, assets?: Maybe<Array<Maybe<(
        { __typename?: 'UserAsset' }
        & Pick<UserAsset, 'id' | 'fileName' | 'size' | 'mimeType'>
      )>>> }
    )> }
  ) }
);

export type CreateAmendmentSummaryMutationVariables = Exact<{
  input: CreateAmendmentSummaryInput;
}>;


export type CreateAmendmentSummaryMutation = (
  { __typename?: 'Mutation' }
  & { createAmendmentSummary: (
    { __typename?: 'CreateAmendmentSummaryResult' }
    & Pick<CreateAmendmentSummaryResult, 'success'>
    & { amendmentSummary?: Maybe<(
      { __typename?: 'AmendmentSummary' }
      & Pick<AmendmentSummary, 'id' | 'content' | 'implementationDate'>
    )> }
  ) }
);

export type DeleteAmendmentSummaryMutationVariables = Exact<{
  summary: DeleteAmendmentSummaryInput;
}>;


export type DeleteAmendmentSummaryMutation = (
  { __typename?: 'Mutation' }
  & { deleteAmendmentSummary: (
    { __typename?: 'DeleteAmendmentSummaryResult' }
    & Pick<DeleteAmendmentSummaryResult, 'success'>
  ) }
);

export type UpdateAmendmentSummaryMutationVariables = Exact<{
  summary: UpdateAmendmentSummaryInput;
}>;


export type UpdateAmendmentSummaryMutation = (
  { __typename?: 'Mutation' }
  & { updateAmendmentSummary: (
    { __typename?: 'UpdateAmendmentSummaryResult' }
    & Pick<UpdateAmendmentSummaryResult, 'success'>
    & { amendmentSummary?: Maybe<(
      { __typename?: 'AmendmentSummary' }
      & Pick<AmendmentSummary, 'content'>
    )> }
  ) }
);

export type CreateAuditRapportMutationVariables = Exact<{
  rapport: CreateAuditRapportInput;
}>;


export type CreateAuditRapportMutation = (
  { __typename?: 'Mutation' }
  & { createAuditRapport: (
    { __typename?: 'CreateAuditRapportResult' }
    & Pick<CreateAuditRapportResult, 'success'>
    & { rapport: (
      { __typename?: 'AuditRapport' }
      & Pick<AuditRapport, 'id' | 'status' | 'source' | 'createdAt'>
    ) }
  ) }
);

export type CreateAuditMutationVariables = Exact<{
  audit: CreateAuditInput;
}>;


export type CreateAuditMutation = (
  { __typename?: 'Mutation' }
  & { createAudit: (
    { __typename?: 'CreateAuditResult' }
    & Pick<CreateAuditResult, 'success' | 'jobId'>
    & { audit?: Maybe<(
      { __typename?: 'Audit' }
      & Pick<Audit, 'id'>
    )> }
  ) }
);

export type DeleteAuditRapportMutationVariables = Exact<{
  rapport: DeleteAuditRapportInput;
}>;


export type DeleteAuditRapportMutation = (
  { __typename?: 'Mutation' }
  & { deleteAuditRapport: (
    { __typename?: 'DeleteAuditRapportResult' }
    & Pick<DeleteAuditRapportResult, 'success'>
  ) }
);

export type DeleteAuditMutationVariables = Exact<{
  audit: DeleteAuditInput;
}>;


export type DeleteAuditMutation = (
  { __typename?: 'Mutation' }
  & { deleteAudit: (
    { __typename?: 'DeleteAuditResult' }
    & Pick<DeleteAuditResult, 'success'>
  ) }
);

export type GetAuditOptionsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
}>;


export type GetAuditOptionsQuery = (
  { __typename?: 'Query' }
  & { audits: (
    { __typename?: 'AuditPaginatedResult' }
    & { items: Array<(
      { __typename?: 'Audit' }
      & Pick<Audit, 'id' | 'name'>
    )> }
  ) }
);

export type GetAuditRapportsQueryVariables = Exact<{
  auditId: Scalars['ID'];
}>;


export type GetAuditRapportsQuery = (
  { __typename?: 'Query' }
  & { getAuditRapports: Array<(
    { __typename?: 'AuditRapport' }
    & Pick<AuditRapport, 'id' | 'createdAt' | 'status' | 'source'>
  )> }
);

export type GetAuditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAuditQuery = (
  { __typename?: 'Query' }
  & { audit?: Maybe<(
    { __typename?: 'Audit' }
    & Pick<Audit, 'id' | 'name' | 'year' | 'quarter'>
    & { auditor?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    )>, sites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name' | 'id'>
    )>, themes: Array<(
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'slug' | 'name'>
    )>, requirementAggregates: Array<(
      { __typename?: 'RequirementAggregate' }
      & Pick<RequirementAggregate, 'status' | 'count'>
    )>, actionAggregates: Array<(
      { __typename?: 'ActionAggregate' }
      & Pick<ActionAggregate, 'status' | 'count'>
    )>, rootRequirements: Array<(
      { __typename?: 'Requirement' }
      & Pick<Requirement, 'id'>
      & { siteStatuses: Array<(
        { __typename?: 'RequirementSiteStatus' }
        & Pick<RequirementSiteStatus, 'id' | 'status'>
      )>, resource: (
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'type' | 'data'>
        & { theme?: Maybe<(
          { __typename?: 'Theme' }
          & Pick<Theme, 'id' | 'slug' | 'name'>
        )>, summary?: Maybe<(
          { __typename?: 'ResourceSummary' }
          & Pick<ResourceSummary, 'id' | 'content'>
        )> }
      ) }
    )>, changedRootRequirements: Array<(
      { __typename?: 'Requirement' }
      & Pick<Requirement, 'id'>
      & { siteStatuses: Array<(
        { __typename?: 'RequirementSiteStatus' }
        & Pick<RequirementSiteStatus, 'id' | 'status'>
      )>, resource: (
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'type' | 'data'>
        & { theme?: Maybe<(
          { __typename?: 'Theme' }
          & Pick<Theme, 'id' | 'slug' | 'name'>
        )>, summary?: Maybe<(
          { __typename?: 'ResourceSummary' }
          & Pick<ResourceSummary, 'id' | 'content'>
        )> }
      ) }
    )>, addedRootRequirements: Array<(
      { __typename?: 'Requirement' }
      & Pick<Requirement, 'id'>
      & { siteStatuses: Array<(
        { __typename?: 'RequirementSiteStatus' }
        & Pick<RequirementSiteStatus, 'id' | 'status'>
      )>, resource: (
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'type' | 'data'>
        & { theme?: Maybe<(
          { __typename?: 'Theme' }
          & Pick<Theme, 'id' | 'slug' | 'name'>
        )>, summary?: Maybe<(
          { __typename?: 'ResourceSummary' }
          & Pick<ResourceSummary, 'id' | 'content'>
        )> }
      ) }
    )> }
  )> }
);

export type GetAuditsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
}>;


export type GetAuditsQuery = (
  { __typename?: 'Query' }
  & { audits: (
    { __typename?: 'AuditPaginatedResult' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'count' | 'perPage' | 'page' | 'total' | 'lastPage'>
    ), items: Array<(
      { __typename?: 'Audit' }
      & Pick<Audit, 'id' | 'name' | 'year' | 'quarter'>
      & { auditor?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
      )>, sites: Array<(
        { __typename?: 'Site' }
        & Pick<Site, 'id' | 'name'>
      )>, themes: Array<(
        { __typename?: 'Theme' }
        & Pick<Theme, 'id' | 'name'>
      )>, requirementAggregates: Array<(
        { __typename?: 'RequirementAggregate' }
        & Pick<RequirementAggregate, 'count' | 'status'>
      )>, actionAggregates: Array<(
        { __typename?: 'ActionAggregate' }
        & Pick<ActionAggregate, 'status' | 'count'>
      )> }
    )> }
  ) }
);

export type GetWizardAuditQueryVariables = Exact<{
  auditId: Scalars['ID'];
}>;


export type GetWizardAuditQuery = (
  { __typename?: 'Query' }
  & { audit?: Maybe<(
    { __typename?: 'Audit' }
    & Pick<Audit, 'id' | 'name'>
    & { themes: Array<(
      { __typename?: 'Theme' }
      & Pick<Theme, 'id'>
    )>, sites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'id'>
    )>, rootRequirements: Array<(
      { __typename?: 'Requirement' }
      & Pick<Requirement, 'id'>
      & { resource: (
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'externalResourceReference' | 'type'>
        & { theme?: Maybe<(
          { __typename?: 'Theme' }
          & Pick<Theme, 'id' | 'name'>
        )> }
      ) }
    )> }
  )> }
);

export type GetWizardAuditsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
}>;


export type GetWizardAuditsQuery = (
  { __typename?: 'Query' }
  & { audits: (
    { __typename?: 'AuditPaginatedResult' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'count' | 'perPage' | 'page' | 'total' | 'lastPage'>
    ), items: Array<(
      { __typename?: 'Audit' }
      & Pick<Audit, 'id' | 'name'>
    )> }
  ) }
);

export type UpdateAuditMutationVariables = Exact<{
  audit: UpdateAuditInput;
}>;


export type UpdateAuditMutation = (
  { __typename?: 'Mutation' }
  & { updateAudit: (
    { __typename?: 'UpdateAuditResult' }
    & Pick<UpdateAuditResult, 'success'>
    & { audit?: Maybe<(
      { __typename?: 'Audit' }
      & Pick<Audit, 'id' | 'name'>
    )> }
  ) }
);

export type CreateCompanyMutationVariables = Exact<{
  company: CreateCompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: (
    { __typename?: 'CreateCompanyResult' }
    & Pick<CreateCompanyResult, 'success'>
  ) }
);

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompany: (
    { __typename?: 'DeleteCompanyResult' }
    & Pick<DeleteCompanyResult, 'success'>
  ) }
);

export type GetCompaniesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
}>;


export type GetCompaniesQuery = (
  { __typename?: 'Query' }
  & { companies: (
    { __typename?: 'CompanyPaginatedResult' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'count' | 'perPage' | 'page' | 'total' | 'lastPage'>
    ), items: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'registrationNumber' | 'siteCount' | 'userCount'>
      & { themes: Array<(
        { __typename?: 'Theme' }
        & Pick<Theme, 'id' | 'slug' | 'name'>
      )>, subscription: (
        { __typename?: 'CompanySubscription' }
        & Pick<CompanySubscription, 'id' | 'name' | 'slug' | 'type'>
      ) }
    )> }
  ) }
);

export type GetCompanySitesQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type GetCompanySitesQuery = (
  { __typename?: 'Query' }
  & { companySites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name' | 'address' | 'postcode' | 'city' | 'country' | 'isHeadquarters'>
  )> }
);

export type GetCompanyUsersQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type GetCompanyUsersQuery = (
  { __typename?: 'Query' }
  & { companyUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'inviteCode' | 'phoneNumber' | 'function' | 'sector'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'name' | 'id' | 'slug'>
    )> }
  )> }
);

export type GetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { subscriptions: Array<(
    { __typename?: 'CompanySubscription' }
    & Pick<CompanySubscription, 'id' | 'name'>
  )> }
);

export type GetTenantUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantUsersQuery = (
  { __typename?: 'Query' }
  & { tenantUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'inviteCode'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'slug' | 'name'>
    )> }
  )> }
);

export type GetViewerCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetViewerCompaniesQuery = (
  { __typename?: 'Query' }
  & { viewerCompanies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'registrationNumber' | 'siteCount' | 'userCount'>
    & { themes: Array<(
      { __typename?: 'Theme' }
      & Pick<Theme, 'id'>
    )>, subscription: (
      { __typename?: 'CompanySubscription' }
      & Pick<CompanySubscription, 'id' | 'name' | 'slug' | 'type'>
    ) }
  )> }
);

export type UpdateCompanyMutationVariables = Exact<{
  company: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'UpdateCompanyResult' }
    & Pick<UpdateCompanyResult, 'success'>
  ) }
);

export type UpdateUserTenantAccessMutationVariables = Exact<{
  input: UpdateUserTenantAccessInput;
}>;


export type UpdateUserTenantAccessMutation = (
  { __typename?: 'Mutation' }
  & { updateUserTenantAccess: (
    { __typename?: 'UpdateUserTenantAccessResult' }
    & Pick<UpdateUserTenantAccessResult, 'success'>
  ) }
);

export type GetJobStatusQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GetJobStatusQuery = (
  { __typename?: 'Query' }
  & { jobStatus: (
    { __typename?: 'JobStatusResult' }
    & Pick<JobStatusResult, 'status'>
  ) }
);

export type GetLawsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLawsQuery = (
  { __typename?: 'Query' }
  & { laws: Array<(
    { __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'externalResourceReference' | 'type' | 'data'>
    & { theme?: Maybe<(
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'slug' | 'name'>
    )>, summary?: Maybe<(
      { __typename?: 'ResourceSummary' }
      & Pick<ResourceSummary, 'id' | 'content'>
    )> }
  )> }
);

export type CreateAuditRemarkMutationVariables = Exact<{
  remark: CreateRemarkInput;
}>;


export type CreateAuditRemarkMutation = (
  { __typename?: 'Mutation' }
  & { createRemark: (
    { __typename?: 'CreateRemarkResult' }
    & Pick<CreateRemarkResult, 'success'>
    & { remark?: Maybe<(
      { __typename?: 'Remark' }
      & Pick<Remark, 'id' | 'content' | 'determinationDate' | 'identifiedBy'>
      & { assets?: Maybe<Array<Maybe<(
        { __typename?: 'UserAsset' }
        & Pick<UserAsset, 'id' | 'fileName' | 'size' | 'mimeType'>
      )>>> }
    )> }
  ) }
);

export type DeleteRemarkMutationVariables = Exact<{
  remarkId: Scalars['ID'];
}>;


export type DeleteRemarkMutation = (
  { __typename?: 'Mutation' }
  & { deleteRemark: (
    { __typename?: 'DeleteRemarkResult' }
    & Pick<DeleteRemarkResult, 'success'>
  ) }
);

export type GetOverviewRemarksQueryVariables = Exact<{
  page?: Maybe<PageFilter>;
  filter?: Maybe<RemarkFilter>;
}>;


export type GetOverviewRemarksQuery = (
  { __typename?: 'Query' }
  & { remarks: (
    { __typename?: 'RemarkPaginationResult' }
    & { items: Array<(
      { __typename?: 'Remark' }
      & Pick<Remark, 'id' | 'content' | 'determinationDate' | 'identifiedBy'>
      & { assets?: Maybe<Array<Maybe<(
        { __typename?: 'UserAsset' }
        & Pick<UserAsset, 'id' | 'fileName' | 'mimeType' | 'size'>
      )>>>, requirement?: Maybe<(
        { __typename?: 'Requirement' }
        & Pick<Requirement, 'id'>
        & { resource: (
          { __typename?: 'Resource' }
          & Pick<Resource, 'id' | 'type' | 'data'>
          & { root?: Maybe<(
            { __typename?: 'Resource' }
            & Pick<Resource, 'id' | 'type' | 'data'>
          )> }
        ) }
      )>, audit?: Maybe<(
        { __typename?: 'Audit' }
        & Pick<Audit, 'id' | 'name'>
        & { sites: Array<(
          { __typename?: 'Site' }
          & Pick<Site, 'id' | 'name'>
        )> }
      )> }
    )>, pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'count' | 'total' | 'page' | 'perPage' | 'lastPage'>
    ) }
  ) }
);

export type GetRequirementRemarksQueryVariables = Exact<{
  requirementId: Scalars['ID'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
}>;


export type GetRequirementRemarksQuery = (
  { __typename?: 'Query' }
  & { requirementRemarks: (
    { __typename?: 'RemarkPaginationResult' }
    & { items: Array<(
      { __typename?: 'Remark' }
      & Pick<Remark, 'id' | 'content' | 'determinationDate' | 'identifiedBy'>
      & { assets?: Maybe<Array<Maybe<(
        { __typename?: 'UserAsset' }
        & Pick<UserAsset, 'id' | 'fileName' | 'mimeType' | 'size'>
      )>>> }
    )>, pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'lastPage' | 'page'>
    ) }
  ) }
);

export type UpdateRemarkMutationVariables = Exact<{
  remark: UpdateRemarkInput;
}>;


export type UpdateRemarkMutation = (
  { __typename?: 'Mutation' }
  & { updateRemark: (
    { __typename?: 'UpdateRemarkResult' }
    & Pick<UpdateRemarkResult, 'success'>
    & { remark?: Maybe<(
      { __typename?: 'Remark' }
      & Pick<Remark, 'id' | 'content' | 'determinationDate' | 'identifiedBy'>
      & { assets?: Maybe<Array<Maybe<(
        { __typename?: 'UserAsset' }
        & Pick<UserAsset, 'id' | 'fileName' | 'size' | 'mimeType'>
      )>>> }
    )> }
  ) }
);

export type DeleteRequirementMutationVariables = Exact<{
  requirement: DeleteRequirementInput;
}>;


export type DeleteRequirementMutation = (
  { __typename?: 'Mutation' }
  & { deleteRequirement: (
    { __typename?: 'DeleteRequirementResult' }
    & Pick<DeleteRequirementResult, 'success'>
  ) }
);

export type GetRequirementChildrenQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRequirementChildrenQuery = (
  { __typename?: 'Query' }
  & { childRequirements: Array<(
    { __typename?: 'Requirement' }
    & Pick<Requirement, 'id' | 'actionCount' | 'remarkCount' | 'resourceStatus'>
    & { siteStatuses: Array<(
      { __typename?: 'RequirementSiteStatus' }
      & Pick<RequirementSiteStatus, 'status'>
      & { site: (
        { __typename?: 'Site' }
        & Pick<Site, 'id'>
      ) }
    )>, resource: (
      { __typename?: 'Resource' }
      & Pick<Resource, 'hasChildren' | 'id' | 'type' | 'implementationDate' | 'data'>
    ) }
  )> }
);

export type GetRequirementQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRequirementQuery = (
  { __typename?: 'Query' }
  & { requirement?: Maybe<(
    { __typename?: 'Requirement' }
    & Pick<Requirement, 'id' | 'actionCount' | 'remarkCount' | 'resourceStatus'>
    & { siteStatuses: Array<(
      { __typename?: 'RequirementSiteStatus' }
      & Pick<RequirementSiteStatus, 'id' | 'status'>
      & { site: (
        { __typename?: 'Site' }
        & Pick<Site, 'id' | 'name' | 'isHeadquarters'>
      ) }
    )>, requirementAggregates: Array<(
      { __typename?: 'RequirementAggregate' }
      & Pick<RequirementAggregate, 'status' | 'count'>
    )>, actionAggregates: Array<(
      { __typename?: 'ActionAggregate' }
      & Pick<ActionAggregate, 'status' | 'count'>
    )>, resource: (
      { __typename?: 'Resource' }
      & Pick<Resource, 'id' | 'hasChildren' | 'type' | 'implementationDate' | 'data'>
      & { theme?: Maybe<(
        { __typename?: 'Theme' }
        & Pick<Theme, 'id' | 'name' | 'slug'>
      )>, amendmentSummaries: Array<(
        { __typename?: 'AmendmentSummary' }
        & Pick<AmendmentSummary, 'id' | 'content' | 'implementationDate'>
      )>, summary?: Maybe<(
        { __typename?: 'ResourceSummary' }
        & Pick<ResourceSummary, 'id' | 'content'>
      )> }
    ) }
  )> }
);

export type UpdateRequirementStatusMutationVariables = Exact<{
  requirement: UpdateRequirementStatusInput;
}>;


export type UpdateRequirementStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateRequirementStatus: (
    { __typename?: 'UpdateRequirementStatusResult' }
    & Pick<UpdateRequirementStatusResult, 'success'>
    & { requirement?: Maybe<(
      { __typename?: 'Requirement' }
      & Pick<Requirement, 'id'>
      & { siteStatuses: Array<(
        { __typename?: 'RequirementSiteStatus' }
        & Pick<RequirementSiteStatus, 'status'>
      )> }
    )>, parentRequirements?: Maybe<Array<(
      { __typename?: 'Requirement' }
      & Pick<Requirement, 'id'>
      & { siteStatuses: Array<(
        { __typename?: 'RequirementSiteStatus' }
        & Pick<RequirementSiteStatus, 'status'>
      )> }
    )>> }
  ) }
);

export type GetResourceQueryVariables = Exact<{
  resourceId: Scalars['ID'];
}>;


export type GetResourceQuery = (
  { __typename?: 'Query' }
  & { resource: (
    { __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'type' | 'implementationDate' | 'data' | 'hasChildren'>
    & { amendmentSummaries: Array<(
      { __typename?: 'AmendmentSummary' }
      & Pick<AmendmentSummary, 'id' | 'content' | 'implementationDate'>
    )>, summary?: Maybe<(
      { __typename?: 'ResourceSummary' }
      & Pick<ResourceSummary, 'id' | 'content'>
    )> }
  ) }
);

export type GetResourcesQueryVariables = Exact<{
  resourceId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
}>;


export type GetResourcesQuery = (
  { __typename?: 'Query' }
  & { resources: (
    { __typename?: 'ResourcePaginatedResult' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'count' | 'total' | 'page' | 'perPage' | 'lastPage'>
    ), items: Array<(
      { __typename?: 'Resource' }
      & Pick<Resource, 'id' | 'type' | 'implementationDate' | 'data' | 'hasChildren'>
      & { downloadableDocuments?: Maybe<Array<(
        { __typename?: 'LegalAsset' }
        & Pick<LegalAsset, 'id' | 'title' | 'source'>
      )>> }
    )> }
  ) }
);

export type UpdateResourceSummaryMutationVariables = Exact<{
  summary: UpdateResourceSummaryInput;
}>;


export type UpdateResourceSummaryMutation = (
  { __typename?: 'Mutation' }
  & { updateResourceSummary: (
    { __typename?: 'UpdateResourceSummaryResult' }
    & Pick<UpdateResourceSummaryResult, 'success'>
    & { resource: (
      { __typename?: 'Resource' }
      & Pick<Resource, 'id'>
    ) }
  ) }
);

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'slug'>
  )> }
);

export type CreateSiteMutationVariables = Exact<{
  site: CreateSiteInput;
}>;


export type CreateSiteMutation = (
  { __typename?: 'Mutation' }
  & { createSite: (
    { __typename?: 'CreateSiteResult' }
    & Pick<CreateSiteResult, 'success'>
    & { site?: Maybe<(
      { __typename?: 'Site' }
      & Pick<Site, 'id'>
    )> }
  ) }
);

export type DeleteSiteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSiteMutation = (
  { __typename?: 'Mutation' }
  & { deleteSite: (
    { __typename?: 'DeleteSiteResult' }
    & Pick<DeleteSiteResult, 'success'>
  ) }
);

export type FindSiteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FindSiteQuery = (
  { __typename?: 'Query' }
  & { site?: Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name' | 'userCount' | 'fullAddress'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    ) }
  )> }
);

export type GetSiteUsersQueryVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type GetSiteUsersQuery = (
  { __typename?: 'Query' }
  & { siteUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'inviteCode'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateSiteAccessMutationVariables = Exact<{
  siteId: Scalars['ID'];
  userIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdateSiteAccessMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteAccess: (
    { __typename?: 'UpdateSiteAccessResult' }
    & Pick<UpdateSiteAccessResult, 'success'>
  ) }
);

export type UpdateSiteMutationVariables = Exact<{
  site: UpdateSiteInput;
}>;


export type UpdateSiteMutation = (
  { __typename?: 'Mutation' }
  & { updateSite: (
    { __typename?: 'UpdateSiteResult' }
    & Pick<UpdateSiteResult, 'success'>
    & { site?: Maybe<(
      { __typename?: 'Site' }
      & Pick<Site, 'id'>
    )> }
  ) }
);

export type GetRequirementStatusesQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type GetRequirementStatusesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'requirementStatuses'>
);

export type GetThemesQueryVariables = Exact<{
  companyId?: Maybe<Scalars['ID']>;
}>;


export type GetThemesQuery = (
  { __typename?: 'Query' }
  & { themes: Array<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id' | 'name' | 'slug'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'CreateUserResult' }
    & Pick<CreateUserResult, 'success'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'DeleteUserResult' }
    & Pick<DeleteUserResult, 'success'>
  ) }
);

export type GetUserCompanySitesQueryVariables = Exact<{
  userId: Scalars['ID'];
  companyId: Scalars['ID'];
}>;


export type GetUserCompanySitesQuery = (
  { __typename?: 'Query' }
  & { userCompanySites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name' | 'address' | 'userCount'>
  )> }
);

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'title' | 'phoneNumber' | 'function' | 'sector'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name' | 'slug'>
    )> }
  )> }
);

export type GetUsersQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserPaginationResult' }
    & { items: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    )> }
  ) }
);

export type InitiatePasswordResetMutationVariables = Exact<{
  input: InitiatePasswordResetInput;
}>;


export type InitiatePasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { initiatePasswordReset: (
    { __typename?: 'InitiatePasswordResetResult' }
    & Pick<InitiatePasswordResetResult, 'success'>
  ) }
);

export type UpdateUserAccessForCompanyMutationVariables = Exact<{
  input: UpdateUserAccessForCompanyInput;
}>;


export type UpdateUserAccessForCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAccessForCompany: (
    { __typename?: 'UpdateUserAccessForCompanyResult' }
    & Pick<UpdateUserAccessForCompanyResult, 'success'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  user: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserResult' }
    & Pick<UpdateUserResult, 'success'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  ) }
);


export const GetViewerDocument = gql`
    query GetViewer {
  viewer {
    id
    email
    firstName
    lastName
    roles {
      id
      slug
    }
    tenant {
      id
      name
    }
  }
}
    `;

/**
 * __useGetViewerQuery__
 *
 * To run a query within a React component, call `useGetViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetViewerQuery(baseOptions?: Apollo.QueryHookOptions<GetViewerQuery, GetViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetViewerQuery, GetViewerQueryVariables>(GetViewerDocument, options);
      }
export function useGetViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetViewerQuery, GetViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetViewerQuery, GetViewerQueryVariables>(GetViewerDocument, options);
        }
export type GetViewerQueryHookResult = ReturnType<typeof useGetViewerQuery>;
export type GetViewerLazyQueryHookResult = ReturnType<typeof useGetViewerLazyQuery>;
export type GetViewerQueryResult = Apollo.QueryResult<GetViewerQuery, GetViewerQueryVariables>;
export const CreateActionDocument = gql`
    mutation CreateAction($action: CreateActionInput!) {
  createAction(action: $action) {
    success
    action {
      id
      description
      status
      cost
      manDays
      assignedTo {
        id
        firstName
        lastName
      }
      deadlineAt
      determinationDate
      assets {
        id
        fileName
        size
        mimeType
      }
    }
  }
}
    `;
export type CreateActionMutationFn = Apollo.MutationFunction<CreateActionMutation, CreateActionMutationVariables>;

/**
 * __useCreateActionMutation__
 *
 * To run a mutation, you first call `useCreateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActionMutation, { data, loading, error }] = useCreateActionMutation({
 *   variables: {
 *      action: // value for 'action'
 *   },
 * });
 */
export function useCreateActionMutation(baseOptions?: Apollo.MutationHookOptions<CreateActionMutation, CreateActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActionMutation, CreateActionMutationVariables>(CreateActionDocument, options);
      }
export type CreateActionMutationHookResult = ReturnType<typeof useCreateActionMutation>;
export type CreateActionMutationResult = Apollo.MutationResult<CreateActionMutation>;
export type CreateActionMutationOptions = Apollo.BaseMutationOptions<CreateActionMutation, CreateActionMutationVariables>;
export const DeleteActionDocument = gql`
    mutation DeleteAction($action: DeleteActionInput!) {
  deleteAction(action: $action) {
    success
  }
}
    `;
export type DeleteActionMutationFn = Apollo.MutationFunction<DeleteActionMutation, DeleteActionMutationVariables>;

/**
 * __useDeleteActionMutation__
 *
 * To run a mutation, you first call `useDeleteActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionMutation, { data, loading, error }] = useDeleteActionMutation({
 *   variables: {
 *      action: // value for 'action'
 *   },
 * });
 */
export function useDeleteActionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActionMutation, DeleteActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActionMutation, DeleteActionMutationVariables>(DeleteActionDocument, options);
      }
export type DeleteActionMutationHookResult = ReturnType<typeof useDeleteActionMutation>;
export type DeleteActionMutationResult = Apollo.MutationResult<DeleteActionMutation>;
export type DeleteActionMutationOptions = Apollo.BaseMutationOptions<DeleteActionMutation, DeleteActionMutationVariables>;
export const ExportActionsDocument = gql`
    query ExportActions($filter: ActionFilter, $sort: ActionSort) {
  exportActions(filter: $filter, sort: $sort) {
    url
  }
}
    `;

/**
 * __useExportActionsQuery__
 *
 * To run a query within a React component, call `useExportActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportActionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useExportActionsQuery(baseOptions?: Apollo.QueryHookOptions<ExportActionsQuery, ExportActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportActionsQuery, ExportActionsQueryVariables>(ExportActionsDocument, options);
      }
export function useExportActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportActionsQuery, ExportActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportActionsQuery, ExportActionsQueryVariables>(ExportActionsDocument, options);
        }
export type ExportActionsQueryHookResult = ReturnType<typeof useExportActionsQuery>;
export type ExportActionsLazyQueryHookResult = ReturnType<typeof useExportActionsLazyQuery>;
export type ExportActionsQueryResult = Apollo.QueryResult<ExportActionsQuery, ExportActionsQueryVariables>;
export const GetOverviewActionsDocument = gql`
    query GetOverviewActions($page: PageFilter!, $filter: ActionFilter, $sort: ActionSort) {
  actions(page: $page, filter: $filter, sort: $sort) {
    pageInfo {
      count
      perPage
      page
      total
      lastPage
    }
    items {
      id
      createdBy {
        id
        firstName
        lastName
      }
      assignedTo {
        id
        firstName
        lastName
      }
      description
      cost
      manDays
      deadlineAt
      determinationDate
      status
      isOverdue
      requirement {
        id
        resource {
          id
          type
          data
          root {
            id
            type
            data
          }
        }
      }
      assets {
        id
        fileName
        mimeType
        size
      }
      audit {
        id
        name
        sites {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetOverviewActionsQuery__
 *
 * To run a query within a React component, call `useGetOverviewActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverviewActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverviewActionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetOverviewActionsQuery(baseOptions: Apollo.QueryHookOptions<GetOverviewActionsQuery, GetOverviewActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverviewActionsQuery, GetOverviewActionsQueryVariables>(GetOverviewActionsDocument, options);
      }
export function useGetOverviewActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverviewActionsQuery, GetOverviewActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverviewActionsQuery, GetOverviewActionsQueryVariables>(GetOverviewActionsDocument, options);
        }
export type GetOverviewActionsQueryHookResult = ReturnType<typeof useGetOverviewActionsQuery>;
export type GetOverviewActionsLazyQueryHookResult = ReturnType<typeof useGetOverviewActionsLazyQuery>;
export type GetOverviewActionsQueryResult = Apollo.QueryResult<GetOverviewActionsQuery, GetOverviewActionsQueryVariables>;
export const GetRequirementActionsDocument = gql`
    query GetRequirementActions($requirementId: ID!, $page: Int!, $perPage: Int!) {
  requirementActions(
    requirementId: $requirementId
    page: {page: $page, perPage: $perPage}
  ) {
    pageInfo {
      lastPage
      page
    }
    items {
      id
      description
      status
      isOverdue
      cost
      manDays
      deadlineAt
      determinationDate
      assets {
        id
        fileName
        mimeType
        size
      }
      assignedTo {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetRequirementActionsQuery__
 *
 * To run a query within a React component, call `useGetRequirementActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequirementActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequirementActionsQuery({
 *   variables: {
 *      requirementId: // value for 'requirementId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetRequirementActionsQuery(baseOptions: Apollo.QueryHookOptions<GetRequirementActionsQuery, GetRequirementActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequirementActionsQuery, GetRequirementActionsQueryVariables>(GetRequirementActionsDocument, options);
      }
export function useGetRequirementActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequirementActionsQuery, GetRequirementActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequirementActionsQuery, GetRequirementActionsQueryVariables>(GetRequirementActionsDocument, options);
        }
export type GetRequirementActionsQueryHookResult = ReturnType<typeof useGetRequirementActionsQuery>;
export type GetRequirementActionsLazyQueryHookResult = ReturnType<typeof useGetRequirementActionsLazyQuery>;
export type GetRequirementActionsQueryResult = Apollo.QueryResult<GetRequirementActionsQuery, GetRequirementActionsQueryVariables>;
export const UpdateActionDocument = gql`
    mutation UpdateAction($action: UpdateActionInput!) {
  updateAction(action: $action) {
    success
    action {
      id
      description
      status
      cost
      manDays
      assignedTo {
        id
        firstName
        lastName
      }
      deadlineAt
      determinationDate
      assets {
        id
        fileName
        size
        mimeType
      }
    }
  }
}
    `;
export type UpdateActionMutationFn = Apollo.MutationFunction<UpdateActionMutation, UpdateActionMutationVariables>;

/**
 * __useUpdateActionMutation__
 *
 * To run a mutation, you first call `useUpdateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionMutation, { data, loading, error }] = useUpdateActionMutation({
 *   variables: {
 *      action: // value for 'action'
 *   },
 * });
 */
export function useUpdateActionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActionMutation, UpdateActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActionMutation, UpdateActionMutationVariables>(UpdateActionDocument, options);
      }
export type UpdateActionMutationHookResult = ReturnType<typeof useUpdateActionMutation>;
export type UpdateActionMutationResult = Apollo.MutationResult<UpdateActionMutation>;
export type UpdateActionMutationOptions = Apollo.BaseMutationOptions<UpdateActionMutation, UpdateActionMutationVariables>;
export const CreateAmendmentSummaryDocument = gql`
    mutation createAmendmentSummary($input: CreateAmendmentSummaryInput!) {
  createAmendmentSummary(amendmentSummary: $input) {
    success
    amendmentSummary {
      id
      content
      implementationDate
    }
  }
}
    `;
export type CreateAmendmentSummaryMutationFn = Apollo.MutationFunction<CreateAmendmentSummaryMutation, CreateAmendmentSummaryMutationVariables>;

/**
 * __useCreateAmendmentSummaryMutation__
 *
 * To run a mutation, you first call `useCreateAmendmentSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAmendmentSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAmendmentSummaryMutation, { data, loading, error }] = useCreateAmendmentSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAmendmentSummaryMutation(baseOptions?: Apollo.MutationHookOptions<CreateAmendmentSummaryMutation, CreateAmendmentSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAmendmentSummaryMutation, CreateAmendmentSummaryMutationVariables>(CreateAmendmentSummaryDocument, options);
      }
export type CreateAmendmentSummaryMutationHookResult = ReturnType<typeof useCreateAmendmentSummaryMutation>;
export type CreateAmendmentSummaryMutationResult = Apollo.MutationResult<CreateAmendmentSummaryMutation>;
export type CreateAmendmentSummaryMutationOptions = Apollo.BaseMutationOptions<CreateAmendmentSummaryMutation, CreateAmendmentSummaryMutationVariables>;
export const DeleteAmendmentSummaryDocument = gql`
    mutation deleteAmendmentSummary($summary: DeleteAmendmentSummaryInput!) {
  deleteAmendmentSummary(amendmentSummary: $summary) {
    success
  }
}
    `;
export type DeleteAmendmentSummaryMutationFn = Apollo.MutationFunction<DeleteAmendmentSummaryMutation, DeleteAmendmentSummaryMutationVariables>;

/**
 * __useDeleteAmendmentSummaryMutation__
 *
 * To run a mutation, you first call `useDeleteAmendmentSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAmendmentSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAmendmentSummaryMutation, { data, loading, error }] = useDeleteAmendmentSummaryMutation({
 *   variables: {
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useDeleteAmendmentSummaryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAmendmentSummaryMutation, DeleteAmendmentSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAmendmentSummaryMutation, DeleteAmendmentSummaryMutationVariables>(DeleteAmendmentSummaryDocument, options);
      }
export type DeleteAmendmentSummaryMutationHookResult = ReturnType<typeof useDeleteAmendmentSummaryMutation>;
export type DeleteAmendmentSummaryMutationResult = Apollo.MutationResult<DeleteAmendmentSummaryMutation>;
export type DeleteAmendmentSummaryMutationOptions = Apollo.BaseMutationOptions<DeleteAmendmentSummaryMutation, DeleteAmendmentSummaryMutationVariables>;
export const UpdateAmendmentSummaryDocument = gql`
    mutation updateAmendmentSummary($summary: UpdateAmendmentSummaryInput!) {
  updateAmendmentSummary(amendmentSummary: $summary) {
    success
    amendmentSummary {
      content
    }
  }
}
    `;
export type UpdateAmendmentSummaryMutationFn = Apollo.MutationFunction<UpdateAmendmentSummaryMutation, UpdateAmendmentSummaryMutationVariables>;

/**
 * __useUpdateAmendmentSummaryMutation__
 *
 * To run a mutation, you first call `useUpdateAmendmentSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAmendmentSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAmendmentSummaryMutation, { data, loading, error }] = useUpdateAmendmentSummaryMutation({
 *   variables: {
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateAmendmentSummaryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAmendmentSummaryMutation, UpdateAmendmentSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAmendmentSummaryMutation, UpdateAmendmentSummaryMutationVariables>(UpdateAmendmentSummaryDocument, options);
      }
export type UpdateAmendmentSummaryMutationHookResult = ReturnType<typeof useUpdateAmendmentSummaryMutation>;
export type UpdateAmendmentSummaryMutationResult = Apollo.MutationResult<UpdateAmendmentSummaryMutation>;
export type UpdateAmendmentSummaryMutationOptions = Apollo.BaseMutationOptions<UpdateAmendmentSummaryMutation, UpdateAmendmentSummaryMutationVariables>;
export const CreateAuditRapportDocument = gql`
    mutation CreateAuditRapport($rapport: CreateAuditRapportInput!) {
  createAuditRapport(rapport: $rapport) {
    success
    rapport {
      id
      status
      source
      createdAt
    }
  }
}
    `;
export type CreateAuditRapportMutationFn = Apollo.MutationFunction<CreateAuditRapportMutation, CreateAuditRapportMutationVariables>;

/**
 * __useCreateAuditRapportMutation__
 *
 * To run a mutation, you first call `useCreateAuditRapportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuditRapportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuditRapportMutation, { data, loading, error }] = useCreateAuditRapportMutation({
 *   variables: {
 *      rapport: // value for 'rapport'
 *   },
 * });
 */
export function useCreateAuditRapportMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuditRapportMutation, CreateAuditRapportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuditRapportMutation, CreateAuditRapportMutationVariables>(CreateAuditRapportDocument, options);
      }
export type CreateAuditRapportMutationHookResult = ReturnType<typeof useCreateAuditRapportMutation>;
export type CreateAuditRapportMutationResult = Apollo.MutationResult<CreateAuditRapportMutation>;
export type CreateAuditRapportMutationOptions = Apollo.BaseMutationOptions<CreateAuditRapportMutation, CreateAuditRapportMutationVariables>;
export const CreateAuditDocument = gql`
    mutation CreateAudit($audit: CreateAuditInput!) {
  createAudit(audit: $audit) {
    success
    jobId
    audit {
      id
    }
  }
}
    `;
export type CreateAuditMutationFn = Apollo.MutationFunction<CreateAuditMutation, CreateAuditMutationVariables>;

/**
 * __useCreateAuditMutation__
 *
 * To run a mutation, you first call `useCreateAuditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuditMutation, { data, loading, error }] = useCreateAuditMutation({
 *   variables: {
 *      audit: // value for 'audit'
 *   },
 * });
 */
export function useCreateAuditMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuditMutation, CreateAuditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuditMutation, CreateAuditMutationVariables>(CreateAuditDocument, options);
      }
export type CreateAuditMutationHookResult = ReturnType<typeof useCreateAuditMutation>;
export type CreateAuditMutationResult = Apollo.MutationResult<CreateAuditMutation>;
export type CreateAuditMutationOptions = Apollo.BaseMutationOptions<CreateAuditMutation, CreateAuditMutationVariables>;
export const DeleteAuditRapportDocument = gql`
    mutation DeleteAuditRapport($rapport: DeleteAuditRapportInput!) {
  deleteAuditRapport(rapport: $rapport) {
    success
  }
}
    `;
export type DeleteAuditRapportMutationFn = Apollo.MutationFunction<DeleteAuditRapportMutation, DeleteAuditRapportMutationVariables>;

/**
 * __useDeleteAuditRapportMutation__
 *
 * To run a mutation, you first call `useDeleteAuditRapportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuditRapportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuditRapportMutation, { data, loading, error }] = useDeleteAuditRapportMutation({
 *   variables: {
 *      rapport: // value for 'rapport'
 *   },
 * });
 */
export function useDeleteAuditRapportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAuditRapportMutation, DeleteAuditRapportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAuditRapportMutation, DeleteAuditRapportMutationVariables>(DeleteAuditRapportDocument, options);
      }
export type DeleteAuditRapportMutationHookResult = ReturnType<typeof useDeleteAuditRapportMutation>;
export type DeleteAuditRapportMutationResult = Apollo.MutationResult<DeleteAuditRapportMutation>;
export type DeleteAuditRapportMutationOptions = Apollo.BaseMutationOptions<DeleteAuditRapportMutation, DeleteAuditRapportMutationVariables>;
export const DeleteAuditDocument = gql`
    mutation DeleteAudit($audit: DeleteAuditInput!) {
  deleteAudit(audit: $audit) {
    success
  }
}
    `;
export type DeleteAuditMutationFn = Apollo.MutationFunction<DeleteAuditMutation, DeleteAuditMutationVariables>;

/**
 * __useDeleteAuditMutation__
 *
 * To run a mutation, you first call `useDeleteAuditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuditMutation, { data, loading, error }] = useDeleteAuditMutation({
 *   variables: {
 *      audit: // value for 'audit'
 *   },
 * });
 */
export function useDeleteAuditMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAuditMutation, DeleteAuditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAuditMutation, DeleteAuditMutationVariables>(DeleteAuditDocument, options);
      }
export type DeleteAuditMutationHookResult = ReturnType<typeof useDeleteAuditMutation>;
export type DeleteAuditMutationResult = Apollo.MutationResult<DeleteAuditMutation>;
export type DeleteAuditMutationOptions = Apollo.BaseMutationOptions<DeleteAuditMutation, DeleteAuditMutationVariables>;
export const GetAuditOptionsDocument = gql`
    query GetAuditOptions($search: String, $companyId: ID!) {
  audits(
    search: $search
    filter: {companyId: $companyId}
    page: {page: 1, perPage: 10}
  ) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAuditOptionsQuery__
 *
 * To run a query within a React component, call `useGetAuditOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditOptionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAuditOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetAuditOptionsQuery, GetAuditOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditOptionsQuery, GetAuditOptionsQueryVariables>(GetAuditOptionsDocument, options);
      }
export function useGetAuditOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditOptionsQuery, GetAuditOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditOptionsQuery, GetAuditOptionsQueryVariables>(GetAuditOptionsDocument, options);
        }
export type GetAuditOptionsQueryHookResult = ReturnType<typeof useGetAuditOptionsQuery>;
export type GetAuditOptionsLazyQueryHookResult = ReturnType<typeof useGetAuditOptionsLazyQuery>;
export type GetAuditOptionsQueryResult = Apollo.QueryResult<GetAuditOptionsQuery, GetAuditOptionsQueryVariables>;
export const GetAuditRapportsDocument = gql`
    query getAuditRapports($auditId: ID!) {
  getAuditRapports(auditId: $auditId) {
    id
    createdAt
    status
    source
  }
}
    `;

/**
 * __useGetAuditRapportsQuery__
 *
 * To run a query within a React component, call `useGetAuditRapportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditRapportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditRapportsQuery({
 *   variables: {
 *      auditId: // value for 'auditId'
 *   },
 * });
 */
export function useGetAuditRapportsQuery(baseOptions: Apollo.QueryHookOptions<GetAuditRapportsQuery, GetAuditRapportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditRapportsQuery, GetAuditRapportsQueryVariables>(GetAuditRapportsDocument, options);
      }
export function useGetAuditRapportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditRapportsQuery, GetAuditRapportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditRapportsQuery, GetAuditRapportsQueryVariables>(GetAuditRapportsDocument, options);
        }
export type GetAuditRapportsQueryHookResult = ReturnType<typeof useGetAuditRapportsQuery>;
export type GetAuditRapportsLazyQueryHookResult = ReturnType<typeof useGetAuditRapportsLazyQuery>;
export type GetAuditRapportsQueryResult = Apollo.QueryResult<GetAuditRapportsQuery, GetAuditRapportsQueryVariables>;
export const GetAuditDocument = gql`
    query getAudit($id: ID!) {
  audit(id: $id) {
    id
    name
    year
    quarter
    auditor {
      id
      firstName
      lastName
      email
    }
    sites {
      name
      id
    }
    themes {
      id
      slug
      name
    }
    requirementAggregates {
      status
      count
    }
    actionAggregates {
      status
      count
    }
    rootRequirements {
      id
      siteStatuses {
        id
        status
      }
      resource {
        id
        type
        theme {
          id
          slug
          name
        }
        data
        summary {
          id
          content
        }
      }
    }
    changedRootRequirements {
      id
      siteStatuses {
        id
        status
      }
      resource {
        id
        type
        theme {
          id
          slug
          name
        }
        data
        summary {
          id
          content
        }
      }
    }
    addedRootRequirements {
      id
      siteStatuses {
        id
        status
      }
      resource {
        id
        type
        theme {
          id
          slug
          name
        }
        data
        summary {
          id
          content
        }
      }
    }
  }
}
    `;

/**
 * __useGetAuditQuery__
 *
 * To run a query within a React component, call `useGetAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAuditQuery(baseOptions: Apollo.QueryHookOptions<GetAuditQuery, GetAuditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditQuery, GetAuditQueryVariables>(GetAuditDocument, options);
      }
export function useGetAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditQuery, GetAuditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditQuery, GetAuditQueryVariables>(GetAuditDocument, options);
        }
export type GetAuditQueryHookResult = ReturnType<typeof useGetAuditQuery>;
export type GetAuditLazyQueryHookResult = ReturnType<typeof useGetAuditLazyQuery>;
export type GetAuditQueryResult = Apollo.QueryResult<GetAuditQuery, GetAuditQueryVariables>;
export const GetAuditsDocument = gql`
    query GetAudits($companyId: ID!, $page: Int!, $perPage: Int!) {
  audits(filter: {companyId: $companyId}, page: {page: $page, perPage: $perPage}) {
    pageInfo {
      count
      perPage
      page
      total
      lastPage
    }
    items {
      id
      auditor {
        id
        firstName
        lastName
        email
      }
      name
      year
      quarter
      sites {
        id
        name
      }
      themes {
        id
        name
      }
      requirementAggregates {
        count
        status
      }
      actionAggregates {
        status
        count
      }
    }
  }
}
    `;

/**
 * __useGetAuditsQuery__
 *
 * To run a query within a React component, call `useGetAuditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetAuditsQuery(baseOptions: Apollo.QueryHookOptions<GetAuditsQuery, GetAuditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditsQuery, GetAuditsQueryVariables>(GetAuditsDocument, options);
      }
export function useGetAuditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditsQuery, GetAuditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditsQuery, GetAuditsQueryVariables>(GetAuditsDocument, options);
        }
export type GetAuditsQueryHookResult = ReturnType<typeof useGetAuditsQuery>;
export type GetAuditsLazyQueryHookResult = ReturnType<typeof useGetAuditsLazyQuery>;
export type GetAuditsQueryResult = Apollo.QueryResult<GetAuditsQuery, GetAuditsQueryVariables>;
export const GetWizardAuditDocument = gql`
    query GetWizardAudit($auditId: ID!) {
  audit(id: $auditId) {
    id
    name
    themes {
      id
    }
    sites {
      id
    }
    rootRequirements {
      id
      resource {
        id
        externalResourceReference
        type
        theme {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetWizardAuditQuery__
 *
 * To run a query within a React component, call `useGetWizardAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWizardAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWizardAuditQuery({
 *   variables: {
 *      auditId: // value for 'auditId'
 *   },
 * });
 */
export function useGetWizardAuditQuery(baseOptions: Apollo.QueryHookOptions<GetWizardAuditQuery, GetWizardAuditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWizardAuditQuery, GetWizardAuditQueryVariables>(GetWizardAuditDocument, options);
      }
export function useGetWizardAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWizardAuditQuery, GetWizardAuditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWizardAuditQuery, GetWizardAuditQueryVariables>(GetWizardAuditDocument, options);
        }
export type GetWizardAuditQueryHookResult = ReturnType<typeof useGetWizardAuditQuery>;
export type GetWizardAuditLazyQueryHookResult = ReturnType<typeof useGetWizardAuditLazyQuery>;
export type GetWizardAuditQueryResult = Apollo.QueryResult<GetWizardAuditQuery, GetWizardAuditQueryVariables>;
export const GetWizardAuditsDocument = gql`
    query GetWizardAudits($search: String, $companyId: ID!) {
  audits(
    search: $search
    filter: {companyId: $companyId}
    page: {page: 1, perPage: 10}
  ) {
    pageInfo {
      count
      perPage
      page
      total
      lastPage
    }
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useGetWizardAuditsQuery__
 *
 * To run a query within a React component, call `useGetWizardAuditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWizardAuditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWizardAuditsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWizardAuditsQuery(baseOptions: Apollo.QueryHookOptions<GetWizardAuditsQuery, GetWizardAuditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWizardAuditsQuery, GetWizardAuditsQueryVariables>(GetWizardAuditsDocument, options);
      }
export function useGetWizardAuditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWizardAuditsQuery, GetWizardAuditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWizardAuditsQuery, GetWizardAuditsQueryVariables>(GetWizardAuditsDocument, options);
        }
export type GetWizardAuditsQueryHookResult = ReturnType<typeof useGetWizardAuditsQuery>;
export type GetWizardAuditsLazyQueryHookResult = ReturnType<typeof useGetWizardAuditsLazyQuery>;
export type GetWizardAuditsQueryResult = Apollo.QueryResult<GetWizardAuditsQuery, GetWizardAuditsQueryVariables>;
export const UpdateAuditDocument = gql`
    mutation UpdateAudit($audit: UpdateAuditInput!) {
  updateAudit(audit: $audit) {
    success
    audit {
      id
      name
    }
  }
}
    `;
export type UpdateAuditMutationFn = Apollo.MutationFunction<UpdateAuditMutation, UpdateAuditMutationVariables>;

/**
 * __useUpdateAuditMutation__
 *
 * To run a mutation, you first call `useUpdateAuditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuditMutation, { data, loading, error }] = useUpdateAuditMutation({
 *   variables: {
 *      audit: // value for 'audit'
 *   },
 * });
 */
export function useUpdateAuditMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAuditMutation, UpdateAuditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAuditMutation, UpdateAuditMutationVariables>(UpdateAuditDocument, options);
      }
export type UpdateAuditMutationHookResult = ReturnType<typeof useUpdateAuditMutation>;
export type UpdateAuditMutationResult = Apollo.MutationResult<UpdateAuditMutation>;
export type UpdateAuditMutationOptions = Apollo.BaseMutationOptions<UpdateAuditMutation, UpdateAuditMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($company: CreateCompanyInput!) {
  createCompany(company: $company) {
    success
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: ID!) {
  deleteCompany(company: {id: $id}) {
    success
  }
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const GetCompaniesDocument = gql`
    query GetCompanies($search: String, $page: Int, $perPage: Int) {
  companies(search: $search, page: {page: $page, perPage: $perPage}) {
    pageInfo {
      count
      perPage
      page
      total
      lastPage
    }
    items {
      id
      name
      registrationNumber
      siteCount
      userCount
      themes {
        id
        slug
        name
      }
      subscription {
        id
        name
        slug
        type
      }
    }
  }
}
    `;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const GetCompanySitesDocument = gql`
    query GetCompanySites($companyId: ID!) {
  companySites(companyId: $companyId) {
    id
    name
    address
    postcode
    city
    country
    isHeadquarters
  }
}
    `;

/**
 * __useGetCompanySitesQuery__
 *
 * To run a query within a React component, call `useGetCompanySitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySitesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanySitesQuery(baseOptions: Apollo.QueryHookOptions<GetCompanySitesQuery, GetCompanySitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanySitesQuery, GetCompanySitesQueryVariables>(GetCompanySitesDocument, options);
      }
export function useGetCompanySitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanySitesQuery, GetCompanySitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanySitesQuery, GetCompanySitesQueryVariables>(GetCompanySitesDocument, options);
        }
export type GetCompanySitesQueryHookResult = ReturnType<typeof useGetCompanySitesQuery>;
export type GetCompanySitesLazyQueryHookResult = ReturnType<typeof useGetCompanySitesLazyQuery>;
export type GetCompanySitesQueryResult = Apollo.QueryResult<GetCompanySitesQuery, GetCompanySitesQueryVariables>;
export const GetCompanyUsersDocument = gql`
    query GetCompanyUsers($companyId: ID!) {
  companyUsers(companyId: $companyId) {
    id
    firstName
    lastName
    email
    inviteCode
    phoneNumber
    function
    sector
    roles {
      name
      id
      slug
    }
  }
}
    `;

/**
 * __useGetCompanyUsersQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyUsersQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(GetCompanyUsersDocument, options);
      }
export function useGetCompanyUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(GetCompanyUsersDocument, options);
        }
export type GetCompanyUsersQueryHookResult = ReturnType<typeof useGetCompanyUsersQuery>;
export type GetCompanyUsersLazyQueryHookResult = ReturnType<typeof useGetCompanyUsersLazyQuery>;
export type GetCompanyUsersQueryResult = Apollo.QueryResult<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>;
export const GetSubscriptionsDocument = gql`
    query getSubscriptions {
  subscriptions {
    id
    name
  }
}
    `;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>;
export const GetTenantUsersDocument = gql`
    query GetTenantUsers {
  tenantUsers {
    id
    firstName
    lastName
    email
    inviteCode
    roles {
      id
      slug
      name
    }
  }
}
    `;

/**
 * __useGetTenantUsersQuery__
 *
 * To run a query within a React component, call `useGetTenantUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantUsersQuery, GetTenantUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantUsersQuery, GetTenantUsersQueryVariables>(GetTenantUsersDocument, options);
      }
export function useGetTenantUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantUsersQuery, GetTenantUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantUsersQuery, GetTenantUsersQueryVariables>(GetTenantUsersDocument, options);
        }
export type GetTenantUsersQueryHookResult = ReturnType<typeof useGetTenantUsersQuery>;
export type GetTenantUsersLazyQueryHookResult = ReturnType<typeof useGetTenantUsersLazyQuery>;
export type GetTenantUsersQueryResult = Apollo.QueryResult<GetTenantUsersQuery, GetTenantUsersQueryVariables>;
export const GetViewerCompaniesDocument = gql`
    query GetViewerCompanies {
  viewerCompanies {
    id
    name
    registrationNumber
    siteCount
    userCount
    themes {
      id
    }
    subscription {
      id
      name
      slug
      type
    }
  }
}
    `;

/**
 * __useGetViewerCompaniesQuery__
 *
 * To run a query within a React component, call `useGetViewerCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewerCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewerCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetViewerCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetViewerCompaniesQuery, GetViewerCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetViewerCompaniesQuery, GetViewerCompaniesQueryVariables>(GetViewerCompaniesDocument, options);
      }
export function useGetViewerCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetViewerCompaniesQuery, GetViewerCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetViewerCompaniesQuery, GetViewerCompaniesQueryVariables>(GetViewerCompaniesDocument, options);
        }
export type GetViewerCompaniesQueryHookResult = ReturnType<typeof useGetViewerCompaniesQuery>;
export type GetViewerCompaniesLazyQueryHookResult = ReturnType<typeof useGetViewerCompaniesLazyQuery>;
export type GetViewerCompaniesQueryResult = Apollo.QueryResult<GetViewerCompaniesQuery, GetViewerCompaniesQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($company: UpdateCompanyInput!) {
  updateCompany(company: $company) {
    success
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UpdateUserTenantAccessDocument = gql`
    mutation UpdateUserTenantAccess($input: UpdateUserTenantAccessInput!) {
  updateUserTenantAccess(input: $input) {
    success
  }
}
    `;
export type UpdateUserTenantAccessMutationFn = Apollo.MutationFunction<UpdateUserTenantAccessMutation, UpdateUserTenantAccessMutationVariables>;

/**
 * __useUpdateUserTenantAccessMutation__
 *
 * To run a mutation, you first call `useUpdateUserTenantAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTenantAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTenantAccessMutation, { data, loading, error }] = useUpdateUserTenantAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserTenantAccessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserTenantAccessMutation, UpdateUserTenantAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserTenantAccessMutation, UpdateUserTenantAccessMutationVariables>(UpdateUserTenantAccessDocument, options);
      }
export type UpdateUserTenantAccessMutationHookResult = ReturnType<typeof useUpdateUserTenantAccessMutation>;
export type UpdateUserTenantAccessMutationResult = Apollo.MutationResult<UpdateUserTenantAccessMutation>;
export type UpdateUserTenantAccessMutationOptions = Apollo.BaseMutationOptions<UpdateUserTenantAccessMutation, UpdateUserTenantAccessMutationVariables>;
export const GetJobStatusDocument = gql`
    query GetJobStatus($jobId: ID!) {
  jobStatus(jobId: $jobId) {
    status
  }
}
    `;

/**
 * __useGetJobStatusQuery__
 *
 * To run a query within a React component, call `useGetJobStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobStatusQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobStatusQuery(baseOptions: Apollo.QueryHookOptions<GetJobStatusQuery, GetJobStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobStatusQuery, GetJobStatusQueryVariables>(GetJobStatusDocument, options);
      }
export function useGetJobStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobStatusQuery, GetJobStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobStatusQuery, GetJobStatusQueryVariables>(GetJobStatusDocument, options);
        }
export type GetJobStatusQueryHookResult = ReturnType<typeof useGetJobStatusQuery>;
export type GetJobStatusLazyQueryHookResult = ReturnType<typeof useGetJobStatusLazyQuery>;
export type GetJobStatusQueryResult = Apollo.QueryResult<GetJobStatusQuery, GetJobStatusQueryVariables>;
export const GetLawsDocument = gql`
    query GetLaws {
  laws {
    id
    externalResourceReference
    type
    theme {
      id
      slug
      name
    }
    data
    summary {
      id
      content
    }
  }
}
    `;

/**
 * __useGetLawsQuery__
 *
 * To run a query within a React component, call `useGetLawsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLawsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLawsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLawsQuery(baseOptions?: Apollo.QueryHookOptions<GetLawsQuery, GetLawsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLawsQuery, GetLawsQueryVariables>(GetLawsDocument, options);
      }
export function useGetLawsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLawsQuery, GetLawsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLawsQuery, GetLawsQueryVariables>(GetLawsDocument, options);
        }
export type GetLawsQueryHookResult = ReturnType<typeof useGetLawsQuery>;
export type GetLawsLazyQueryHookResult = ReturnType<typeof useGetLawsLazyQuery>;
export type GetLawsQueryResult = Apollo.QueryResult<GetLawsQuery, GetLawsQueryVariables>;
export const CreateAuditRemarkDocument = gql`
    mutation createAuditRemark($remark: CreateRemarkInput!) {
  createRemark(remark: $remark) {
    success
    remark {
      id
      content
      determinationDate
      assets {
        id
        fileName
        size
        mimeType
      }
      identifiedBy
    }
  }
}
    `;
export type CreateAuditRemarkMutationFn = Apollo.MutationFunction<CreateAuditRemarkMutation, CreateAuditRemarkMutationVariables>;

/**
 * __useCreateAuditRemarkMutation__
 *
 * To run a mutation, you first call `useCreateAuditRemarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuditRemarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuditRemarkMutation, { data, loading, error }] = useCreateAuditRemarkMutation({
 *   variables: {
 *      remark: // value for 'remark'
 *   },
 * });
 */
export function useCreateAuditRemarkMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuditRemarkMutation, CreateAuditRemarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuditRemarkMutation, CreateAuditRemarkMutationVariables>(CreateAuditRemarkDocument, options);
      }
export type CreateAuditRemarkMutationHookResult = ReturnType<typeof useCreateAuditRemarkMutation>;
export type CreateAuditRemarkMutationResult = Apollo.MutationResult<CreateAuditRemarkMutation>;
export type CreateAuditRemarkMutationOptions = Apollo.BaseMutationOptions<CreateAuditRemarkMutation, CreateAuditRemarkMutationVariables>;
export const DeleteRemarkDocument = gql`
    mutation DeleteRemark($remarkId: ID!) {
  deleteRemark(remark: {id: $remarkId}) {
    success
  }
}
    `;
export type DeleteRemarkMutationFn = Apollo.MutationFunction<DeleteRemarkMutation, DeleteRemarkMutationVariables>;

/**
 * __useDeleteRemarkMutation__
 *
 * To run a mutation, you first call `useDeleteRemarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRemarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRemarkMutation, { data, loading, error }] = useDeleteRemarkMutation({
 *   variables: {
 *      remarkId: // value for 'remarkId'
 *   },
 * });
 */
export function useDeleteRemarkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRemarkMutation, DeleteRemarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRemarkMutation, DeleteRemarkMutationVariables>(DeleteRemarkDocument, options);
      }
export type DeleteRemarkMutationHookResult = ReturnType<typeof useDeleteRemarkMutation>;
export type DeleteRemarkMutationResult = Apollo.MutationResult<DeleteRemarkMutation>;
export type DeleteRemarkMutationOptions = Apollo.BaseMutationOptions<DeleteRemarkMutation, DeleteRemarkMutationVariables>;
export const GetOverviewRemarksDocument = gql`
    query GetOverviewRemarks($page: PageFilter, $filter: RemarkFilter) {
  remarks(page: $page, filter: $filter) {
    items {
      id
      content
      determinationDate
      assets {
        id
        fileName
        mimeType
        size
      }
      identifiedBy
      requirement {
        id
        resource {
          id
          type
          data
          root {
            id
            type
            data
          }
        }
      }
      audit {
        id
        name
        sites {
          id
          name
        }
      }
    }
    pageInfo {
      count
      total
      page
      perPage
      lastPage
    }
  }
}
    `;

/**
 * __useGetOverviewRemarksQuery__
 *
 * To run a query within a React component, call `useGetOverviewRemarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverviewRemarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverviewRemarksQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOverviewRemarksQuery(baseOptions?: Apollo.QueryHookOptions<GetOverviewRemarksQuery, GetOverviewRemarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverviewRemarksQuery, GetOverviewRemarksQueryVariables>(GetOverviewRemarksDocument, options);
      }
export function useGetOverviewRemarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverviewRemarksQuery, GetOverviewRemarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverviewRemarksQuery, GetOverviewRemarksQueryVariables>(GetOverviewRemarksDocument, options);
        }
export type GetOverviewRemarksQueryHookResult = ReturnType<typeof useGetOverviewRemarksQuery>;
export type GetOverviewRemarksLazyQueryHookResult = ReturnType<typeof useGetOverviewRemarksLazyQuery>;
export type GetOverviewRemarksQueryResult = Apollo.QueryResult<GetOverviewRemarksQuery, GetOverviewRemarksQueryVariables>;
export const GetRequirementRemarksDocument = gql`
    query GetRequirementRemarks($requirementId: ID!, $page: Int!, $perPage: Int!) {
  requirementRemarks(
    requirementId: $requirementId
    page: {page: $page, perPage: $perPage}
  ) {
    items {
      id
      content
      determinationDate
      assets {
        id
        fileName
        mimeType
        size
      }
      identifiedBy
    }
    pageInfo {
      lastPage
      page
    }
  }
}
    `;

/**
 * __useGetRequirementRemarksQuery__
 *
 * To run a query within a React component, call `useGetRequirementRemarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequirementRemarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequirementRemarksQuery({
 *   variables: {
 *      requirementId: // value for 'requirementId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetRequirementRemarksQuery(baseOptions: Apollo.QueryHookOptions<GetRequirementRemarksQuery, GetRequirementRemarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequirementRemarksQuery, GetRequirementRemarksQueryVariables>(GetRequirementRemarksDocument, options);
      }
export function useGetRequirementRemarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequirementRemarksQuery, GetRequirementRemarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequirementRemarksQuery, GetRequirementRemarksQueryVariables>(GetRequirementRemarksDocument, options);
        }
export type GetRequirementRemarksQueryHookResult = ReturnType<typeof useGetRequirementRemarksQuery>;
export type GetRequirementRemarksLazyQueryHookResult = ReturnType<typeof useGetRequirementRemarksLazyQuery>;
export type GetRequirementRemarksQueryResult = Apollo.QueryResult<GetRequirementRemarksQuery, GetRequirementRemarksQueryVariables>;
export const UpdateRemarkDocument = gql`
    mutation UpdateRemark($remark: UpdateRemarkInput!) {
  updateRemark(remark: $remark) {
    success
    remark {
      id
      content
      determinationDate
      assets {
        id
        fileName
        size
        mimeType
      }
      identifiedBy
    }
  }
}
    `;
export type UpdateRemarkMutationFn = Apollo.MutationFunction<UpdateRemarkMutation, UpdateRemarkMutationVariables>;

/**
 * __useUpdateRemarkMutation__
 *
 * To run a mutation, you first call `useUpdateRemarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRemarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRemarkMutation, { data, loading, error }] = useUpdateRemarkMutation({
 *   variables: {
 *      remark: // value for 'remark'
 *   },
 * });
 */
export function useUpdateRemarkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRemarkMutation, UpdateRemarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRemarkMutation, UpdateRemarkMutationVariables>(UpdateRemarkDocument, options);
      }
export type UpdateRemarkMutationHookResult = ReturnType<typeof useUpdateRemarkMutation>;
export type UpdateRemarkMutationResult = Apollo.MutationResult<UpdateRemarkMutation>;
export type UpdateRemarkMutationOptions = Apollo.BaseMutationOptions<UpdateRemarkMutation, UpdateRemarkMutationVariables>;
export const DeleteRequirementDocument = gql`
    mutation DeleteRequirement($requirement: DeleteRequirementInput!) {
  deleteRequirement(requirement: $requirement) {
    success
  }
}
    `;
export type DeleteRequirementMutationFn = Apollo.MutationFunction<DeleteRequirementMutation, DeleteRequirementMutationVariables>;

/**
 * __useDeleteRequirementMutation__
 *
 * To run a mutation, you first call `useDeleteRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequirementMutation, { data, loading, error }] = useDeleteRequirementMutation({
 *   variables: {
 *      requirement: // value for 'requirement'
 *   },
 * });
 */
export function useDeleteRequirementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRequirementMutation, DeleteRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRequirementMutation, DeleteRequirementMutationVariables>(DeleteRequirementDocument, options);
      }
export type DeleteRequirementMutationHookResult = ReturnType<typeof useDeleteRequirementMutation>;
export type DeleteRequirementMutationResult = Apollo.MutationResult<DeleteRequirementMutation>;
export type DeleteRequirementMutationOptions = Apollo.BaseMutationOptions<DeleteRequirementMutation, DeleteRequirementMutationVariables>;
export const GetRequirementChildrenDocument = gql`
    query getRequirementChildren($id: ID!) {
  childRequirements(id: $id) {
    id
    siteStatuses {
      status
      site {
        id
      }
    }
    actionCount
    remarkCount
    resourceStatus
    resource {
      hasChildren
      id
      type
      implementationDate
      data
    }
  }
}
    `;

/**
 * __useGetRequirementChildrenQuery__
 *
 * To run a query within a React component, call `useGetRequirementChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequirementChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequirementChildrenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequirementChildrenQuery(baseOptions: Apollo.QueryHookOptions<GetRequirementChildrenQuery, GetRequirementChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequirementChildrenQuery, GetRequirementChildrenQueryVariables>(GetRequirementChildrenDocument, options);
      }
export function useGetRequirementChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequirementChildrenQuery, GetRequirementChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequirementChildrenQuery, GetRequirementChildrenQueryVariables>(GetRequirementChildrenDocument, options);
        }
export type GetRequirementChildrenQueryHookResult = ReturnType<typeof useGetRequirementChildrenQuery>;
export type GetRequirementChildrenLazyQueryHookResult = ReturnType<typeof useGetRequirementChildrenLazyQuery>;
export type GetRequirementChildrenQueryResult = Apollo.QueryResult<GetRequirementChildrenQuery, GetRequirementChildrenQueryVariables>;
export const GetRequirementDocument = gql`
    query getRequirement($id: ID!) {
  requirement(id: $id) {
    id
    siteStatuses {
      id
      site {
        id
        name
        isHeadquarters
      }
      status
    }
    requirementAggregates {
      status
      count
    }
    actionAggregates {
      status
      count
    }
    actionCount
    remarkCount
    resourceStatus
    resource {
      id
      hasChildren
      type
      theme {
        id
        name
        slug
      }
      implementationDate
      data
      amendmentSummaries {
        id
        content
        implementationDate
      }
      summary {
        id
        content
      }
    }
  }
}
    `;

/**
 * __useGetRequirementQuery__
 *
 * To run a query within a React component, call `useGetRequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequirementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequirementQuery(baseOptions: Apollo.QueryHookOptions<GetRequirementQuery, GetRequirementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequirementQuery, GetRequirementQueryVariables>(GetRequirementDocument, options);
      }
export function useGetRequirementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequirementQuery, GetRequirementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequirementQuery, GetRequirementQueryVariables>(GetRequirementDocument, options);
        }
export type GetRequirementQueryHookResult = ReturnType<typeof useGetRequirementQuery>;
export type GetRequirementLazyQueryHookResult = ReturnType<typeof useGetRequirementLazyQuery>;
export type GetRequirementQueryResult = Apollo.QueryResult<GetRequirementQuery, GetRequirementQueryVariables>;
export const UpdateRequirementStatusDocument = gql`
    mutation updateRequirementStatus($requirement: UpdateRequirementStatusInput!) {
  updateRequirementStatus(requirement: $requirement) {
    success
    requirement {
      id
      siteStatuses {
        status
      }
    }
    parentRequirements {
      id
      siteStatuses {
        status
      }
    }
  }
}
    `;
export type UpdateRequirementStatusMutationFn = Apollo.MutationFunction<UpdateRequirementStatusMutation, UpdateRequirementStatusMutationVariables>;

/**
 * __useUpdateRequirementStatusMutation__
 *
 * To run a mutation, you first call `useUpdateRequirementStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequirementStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequirementStatusMutation, { data, loading, error }] = useUpdateRequirementStatusMutation({
 *   variables: {
 *      requirement: // value for 'requirement'
 *   },
 * });
 */
export function useUpdateRequirementStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRequirementStatusMutation, UpdateRequirementStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRequirementStatusMutation, UpdateRequirementStatusMutationVariables>(UpdateRequirementStatusDocument, options);
      }
export type UpdateRequirementStatusMutationHookResult = ReturnType<typeof useUpdateRequirementStatusMutation>;
export type UpdateRequirementStatusMutationResult = Apollo.MutationResult<UpdateRequirementStatusMutation>;
export type UpdateRequirementStatusMutationOptions = Apollo.BaseMutationOptions<UpdateRequirementStatusMutation, UpdateRequirementStatusMutationVariables>;
export const GetResourceDocument = gql`
    query getResource($resourceId: ID!) {
  resource(id: $resourceId) {
    id
    type
    implementationDate
    data
    hasChildren
    amendmentSummaries {
      id
      content
      implementationDate
    }
    summary {
      id
      content
    }
  }
}
    `;

/**
 * __useGetResourceQuery__
 *
 * To run a query within a React component, call `useGetResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useGetResourceQuery(baseOptions: Apollo.QueryHookOptions<GetResourceQuery, GetResourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceQuery, GetResourceQueryVariables>(GetResourceDocument, options);
      }
export function useGetResourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceQuery, GetResourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceQuery, GetResourceQueryVariables>(GetResourceDocument, options);
        }
export type GetResourceQueryHookResult = ReturnType<typeof useGetResourceQuery>;
export type GetResourceLazyQueryHookResult = ReturnType<typeof useGetResourceLazyQuery>;
export type GetResourceQueryResult = Apollo.QueryResult<GetResourceQuery, GetResourceQueryVariables>;
export const GetResourcesDocument = gql`
    query getResources($resourceId: ID!, $page: Int, $perPage: Int) {
  resources(parentId: $resourceId, page: {page: $page, perPage: $perPage}) {
    pageInfo {
      count
      total
      page
      perPage
      lastPage
    }
    items {
      id
      type
      implementationDate
      data
      hasChildren
      downloadableDocuments {
        id
        title
        source
      }
    }
  }
}
    `;

/**
 * __useGetResourcesQuery__
 *
 * To run a query within a React component, call `useGetResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetResourcesQuery(baseOptions: Apollo.QueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, options);
      }
export function useGetResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, options);
        }
export type GetResourcesQueryHookResult = ReturnType<typeof useGetResourcesQuery>;
export type GetResourcesLazyQueryHookResult = ReturnType<typeof useGetResourcesLazyQuery>;
export type GetResourcesQueryResult = Apollo.QueryResult<GetResourcesQuery, GetResourcesQueryVariables>;
export const UpdateResourceSummaryDocument = gql`
    mutation updateResourceSummary($summary: UpdateResourceSummaryInput!) {
  updateResourceSummary(resourceSummary: $summary) {
    success
    resource {
      id
    }
  }
}
    `;
export type UpdateResourceSummaryMutationFn = Apollo.MutationFunction<UpdateResourceSummaryMutation, UpdateResourceSummaryMutationVariables>;

/**
 * __useUpdateResourceSummaryMutation__
 *
 * To run a mutation, you first call `useUpdateResourceSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceSummaryMutation, { data, loading, error }] = useUpdateResourceSummaryMutation({
 *   variables: {
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateResourceSummaryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResourceSummaryMutation, UpdateResourceSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResourceSummaryMutation, UpdateResourceSummaryMutationVariables>(UpdateResourceSummaryDocument, options);
      }
export type UpdateResourceSummaryMutationHookResult = ReturnType<typeof useUpdateResourceSummaryMutation>;
export type UpdateResourceSummaryMutationResult = Apollo.MutationResult<UpdateResourceSummaryMutation>;
export type UpdateResourceSummaryMutationOptions = Apollo.BaseMutationOptions<UpdateResourceSummaryMutation, UpdateResourceSummaryMutationVariables>;
export const GetRolesDocument = gql`
    query getRoles {
  roles {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const CreateSiteDocument = gql`
    mutation createSite($site: CreateSiteInput!) {
  createSite(site: $site) {
    success
    site {
      id
    }
  }
}
    `;
export type CreateSiteMutationFn = Apollo.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useCreateSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
      }
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = Apollo.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = Apollo.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const DeleteSiteDocument = gql`
    mutation deleteSite($id: ID!) {
  deleteSite(site: {id: $id}) {
    success
  }
}
    `;
export type DeleteSiteMutationFn = Apollo.MutationFunction<DeleteSiteMutation, DeleteSiteMutationVariables>;

/**
 * __useDeleteSiteMutation__
 *
 * To run a mutation, you first call `useDeleteSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteMutation, { data, loading, error }] = useDeleteSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSiteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSiteMutation, DeleteSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSiteMutation, DeleteSiteMutationVariables>(DeleteSiteDocument, options);
      }
export type DeleteSiteMutationHookResult = ReturnType<typeof useDeleteSiteMutation>;
export type DeleteSiteMutationResult = Apollo.MutationResult<DeleteSiteMutation>;
export type DeleteSiteMutationOptions = Apollo.BaseMutationOptions<DeleteSiteMutation, DeleteSiteMutationVariables>;
export const FindSiteDocument = gql`
    query findSite($id: ID!) {
  site(id: $id) {
    id
    name
    userCount
    fullAddress
    company {
      id
    }
  }
}
    `;

/**
 * __useFindSiteQuery__
 *
 * To run a query within a React component, call `useFindSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindSiteQuery(baseOptions: Apollo.QueryHookOptions<FindSiteQuery, FindSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSiteQuery, FindSiteQueryVariables>(FindSiteDocument, options);
      }
export function useFindSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSiteQuery, FindSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSiteQuery, FindSiteQueryVariables>(FindSiteDocument, options);
        }
export type FindSiteQueryHookResult = ReturnType<typeof useFindSiteQuery>;
export type FindSiteLazyQueryHookResult = ReturnType<typeof useFindSiteLazyQuery>;
export type FindSiteQueryResult = Apollo.QueryResult<FindSiteQuery, FindSiteQueryVariables>;
export const GetSiteUsersDocument = gql`
    query GetSiteUsers($siteId: ID!) {
  siteUsers(siteId: $siteId) {
    id
    firstName
    lastName
    email
    inviteCode
    roles {
      id
      name
    }
  }
}
    `;

/**
 * __useGetSiteUsersQuery__
 *
 * To run a query within a React component, call `useGetSiteUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteUsersQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetSiteUsersQuery(baseOptions: Apollo.QueryHookOptions<GetSiteUsersQuery, GetSiteUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteUsersQuery, GetSiteUsersQueryVariables>(GetSiteUsersDocument, options);
      }
export function useGetSiteUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteUsersQuery, GetSiteUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteUsersQuery, GetSiteUsersQueryVariables>(GetSiteUsersDocument, options);
        }
export type GetSiteUsersQueryHookResult = ReturnType<typeof useGetSiteUsersQuery>;
export type GetSiteUsersLazyQueryHookResult = ReturnType<typeof useGetSiteUsersLazyQuery>;
export type GetSiteUsersQueryResult = Apollo.QueryResult<GetSiteUsersQuery, GetSiteUsersQueryVariables>;
export const UpdateSiteAccessDocument = gql`
    mutation UpdateSiteAccess($siteId: ID!, $userIds: [ID!]!) {
  updateSiteAccess(input: {siteId: $siteId, userIds: $userIds}) {
    success
  }
}
    `;
export type UpdateSiteAccessMutationFn = Apollo.MutationFunction<UpdateSiteAccessMutation, UpdateSiteAccessMutationVariables>;

/**
 * __useUpdateSiteAccessMutation__
 *
 * To run a mutation, you first call `useUpdateSiteAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteAccessMutation, { data, loading, error }] = useUpdateSiteAccessMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useUpdateSiteAccessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSiteAccessMutation, UpdateSiteAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSiteAccessMutation, UpdateSiteAccessMutationVariables>(UpdateSiteAccessDocument, options);
      }
export type UpdateSiteAccessMutationHookResult = ReturnType<typeof useUpdateSiteAccessMutation>;
export type UpdateSiteAccessMutationResult = Apollo.MutationResult<UpdateSiteAccessMutation>;
export type UpdateSiteAccessMutationOptions = Apollo.BaseMutationOptions<UpdateSiteAccessMutation, UpdateSiteAccessMutationVariables>;
export const UpdateSiteDocument = gql`
    mutation updateSite($site: UpdateSiteInput!) {
  updateSite(site: $site) {
    success
    site {
      id
    }
  }
}
    `;
export type UpdateSiteMutationFn = Apollo.MutationFunction<UpdateSiteMutation, UpdateSiteMutationVariables>;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMutation, { data, loading, error }] = useUpdateSiteMutation({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useUpdateSiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSiteMutation, UpdateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(UpdateSiteDocument, options);
      }
export type UpdateSiteMutationHookResult = ReturnType<typeof useUpdateSiteMutation>;
export type UpdateSiteMutationResult = Apollo.MutationResult<UpdateSiteMutation>;
export type UpdateSiteMutationOptions = Apollo.BaseMutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables>;
export const GetRequirementStatusesDocument = gql`
    query getRequirementStatuses($companyId: ID!) {
  requirementStatuses(companyId: $companyId)
}
    `;

/**
 * __useGetRequirementStatusesQuery__
 *
 * To run a query within a React component, call `useGetRequirementStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequirementStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequirementStatusesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetRequirementStatusesQuery(baseOptions: Apollo.QueryHookOptions<GetRequirementStatusesQuery, GetRequirementStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequirementStatusesQuery, GetRequirementStatusesQueryVariables>(GetRequirementStatusesDocument, options);
      }
export function useGetRequirementStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequirementStatusesQuery, GetRequirementStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequirementStatusesQuery, GetRequirementStatusesQueryVariables>(GetRequirementStatusesDocument, options);
        }
export type GetRequirementStatusesQueryHookResult = ReturnType<typeof useGetRequirementStatusesQuery>;
export type GetRequirementStatusesLazyQueryHookResult = ReturnType<typeof useGetRequirementStatusesLazyQuery>;
export type GetRequirementStatusesQueryResult = Apollo.QueryResult<GetRequirementStatusesQuery, GetRequirementStatusesQueryVariables>;
export const GetThemesDocument = gql`
    query GetThemes($companyId: ID) {
  themes(companyId: $companyId) {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetThemesQuery__
 *
 * To run a query within a React component, call `useGetThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetThemesQuery(baseOptions?: Apollo.QueryHookOptions<GetThemesQuery, GetThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, options);
      }
export function useGetThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThemesQuery, GetThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, options);
        }
export type GetThemesQueryHookResult = ReturnType<typeof useGetThemesQuery>;
export type GetThemesLazyQueryHookResult = ReturnType<typeof useGetThemesLazyQuery>;
export type GetThemesQueryResult = Apollo.QueryResult<GetThemesQuery, GetThemesQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($user: CreateUserInput!) {
  createUser(user: $user) {
    success
    user {
      id
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: ID!) {
  deleteUser(user: {id: $id}) {
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetUserCompanySitesDocument = gql`
    query getUserCompanySites($userId: ID!, $companyId: ID!) {
  userCompanySites(userId: $userId, companyId: $companyId) {
    id
    name
    address
    userCount
  }
}
    `;

/**
 * __useGetUserCompanySitesQuery__
 *
 * To run a query within a React component, call `useGetUserCompanySitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCompanySitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCompanySitesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetUserCompanySitesQuery(baseOptions: Apollo.QueryHookOptions<GetUserCompanySitesQuery, GetUserCompanySitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCompanySitesQuery, GetUserCompanySitesQueryVariables>(GetUserCompanySitesDocument, options);
      }
export function useGetUserCompanySitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCompanySitesQuery, GetUserCompanySitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCompanySitesQuery, GetUserCompanySitesQueryVariables>(GetUserCompanySitesDocument, options);
        }
export type GetUserCompanySitesQueryHookResult = ReturnType<typeof useGetUserCompanySitesQuery>;
export type GetUserCompanySitesLazyQueryHookResult = ReturnType<typeof useGetUserCompanySitesLazyQuery>;
export type GetUserCompanySitesQueryResult = Apollo.QueryResult<GetUserCompanySitesQuery, GetUserCompanySitesQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: ID!) {
  user(id: $id) {
    id
    email
    firstName
    lastName
    title
    phoneNumber
    function
    sector
    roles {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($search: String!) {
  users(search: $search) {
    items {
      id
      email
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const InitiatePasswordResetDocument = gql`
    mutation InitiatePasswordReset($input: InitiatePasswordResetInput!) {
  initiatePasswordReset(input: $input) {
    success
  }
}
    `;
export type InitiatePasswordResetMutationFn = Apollo.MutationFunction<InitiatePasswordResetMutation, InitiatePasswordResetMutationVariables>;

/**
 * __useInitiatePasswordResetMutation__
 *
 * To run a mutation, you first call `useInitiatePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiatePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiatePasswordResetMutation, { data, loading, error }] = useInitiatePasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiatePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<InitiatePasswordResetMutation, InitiatePasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiatePasswordResetMutation, InitiatePasswordResetMutationVariables>(InitiatePasswordResetDocument, options);
      }
export type InitiatePasswordResetMutationHookResult = ReturnType<typeof useInitiatePasswordResetMutation>;
export type InitiatePasswordResetMutationResult = Apollo.MutationResult<InitiatePasswordResetMutation>;
export type InitiatePasswordResetMutationOptions = Apollo.BaseMutationOptions<InitiatePasswordResetMutation, InitiatePasswordResetMutationVariables>;
export const UpdateUserAccessForCompanyDocument = gql`
    mutation updateUserAccessForCompany($input: UpdateUserAccessForCompanyInput!) {
  updateUserAccessForCompany(input: $input) {
    success
  }
}
    `;
export type UpdateUserAccessForCompanyMutationFn = Apollo.MutationFunction<UpdateUserAccessForCompanyMutation, UpdateUserAccessForCompanyMutationVariables>;

/**
 * __useUpdateUserAccessForCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccessForCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccessForCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccessForCompanyMutation, { data, loading, error }] = useUpdateUserAccessForCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAccessForCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAccessForCompanyMutation, UpdateUserAccessForCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAccessForCompanyMutation, UpdateUserAccessForCompanyMutationVariables>(UpdateUserAccessForCompanyDocument, options);
      }
export type UpdateUserAccessForCompanyMutationHookResult = ReturnType<typeof useUpdateUserAccessForCompanyMutation>;
export type UpdateUserAccessForCompanyMutationResult = Apollo.MutationResult<UpdateUserAccessForCompanyMutation>;
export type UpdateUserAccessForCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateUserAccessForCompanyMutation, UpdateUserAccessForCompanyMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($user: UpdateUserInput!) {
  updateUser(user: $user) {
    success
    user {
      id
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;